import request from "@/utils/request.util";

export const getListVoucher = async (payload) => {
  const params = {
    params: payload,
  };
  return request.get("/vouchers/list", params);
};

export const getVoucherDetail = async (id) => {
  const params = {
    ticket_group: {
      id: id,
    },
  };
  return request.get(`/vouchers/show_ticket_group`, { params });
};

export const updateVoucherDetail = async (payload) => {
  return request.put(`/vouchers/update_ticket_group`, payload, {
    headers: { "content-type": "multipart/form-data" },
  });
};

export const createVoucher = async (payload) => {
  return request.post("/vouchers/create_ticket_group", payload, {
    headers: { "content-type": "multipart/form-data" },
  });
};

export const detailVoucherRedeem = async (payload) => {
  const params = {
    id: payload.code,
    restaurant_id: payload.restaurant_id,
  };
  return request.get(`/vouchers/show_voucher_redeem`, { params });
};

export const redeemVoucher = async (payload) => {
  return request.post(`/vouchers/redeem`, payload);
};

export const updateVoucherRedeem = async (payload) => {
  return request.put(`/vouchers/edit_voucher_redeem`, payload);
};

export const deleteVoucher = async (payload) => {
  return request.delete(`/vouchers/delete_ticket_group`, { data: payload });
};

export const duplicateVoucher = async (payload) => {
  return request.post(`/vouchers/duplicate_ticket_group`, payload);
};

export const exportListVoucher = async (payload) => {
  return request.post("/vouchers/export_vouchers_list", payload);
};
