<template>
  <v-snackbar
    v-show="toastStore.visible"
    v-model="visible"
    :timeout="10000000000"
    bottom
    right
    :width="isMobileScreen ? '92%' : '26.786rem'"
    rounded="5px"
    transition="slide-y-reverse-transition"
  >
    <div class="toast-box">
      <v-icon class="icon-close" small @click="toastStore.resetToast()">
        $iconCloseDialog
      </v-icon>
      <v-row class="ma-0">
        <v-col
          cols="3"
          class="icon-alert md-size py-2 px-7 d-flex justify-center align-center"
        >
          <IconToastAlert :iconType="iconType" />
        </v-col>
        <v-col cols="9" class="py-0 px-5">
          <h3 class="text-title mb-1">{{ title }}</h3>
          <p class="text-content ma-0">{{ content }}</p>
        </v-col>
      </v-row>
      <v-progress-linear
        absolute
        bottom
        reverse
        :background-color="iconType === 'smiley' ? '#00cc69' : '#fe365c'"
        :color="iconType === 'smiley' ? '#E1F2EA' : '#FBE5EA'"
        :value="Math.floor(100 * (toastStore.currentTime / toastStore.timeout))"
      />
    </div>
  </v-snackbar>
</template>

<script setup>
import { useVuetify } from "@/composables/vuetify.composable";
import { useToastStore } from "@/stores/toast.store";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const toastStore = useToastStore();
const vuetify = useVuetify();

const { visible, content, title, iconType } = storeToRefs(toastStore);

const isMobileScreen = computed(() => {
  const screenSize = vuetify.breakpoint.width;
  if (screenSize < 992) {
    return true;
  }
  return false;
});
</script>

<style lang="scss" scoped>
:deep(.v-snack__wrapper) {
  box-shadow: none;
  background: #ffffff;
  right: 15px;
  bottom: 15px;
  padding: 32px 0;
  padding-bottom: 28px;
  border-bottom: 4px;
  border-color: #fe365c;
  margin: 0;
}
:deep(.v-sheet.v-snack__wrapper:not(.v-sheet--outlined)) {
  border-radius: 5px;
}
:deep(.v-snack__content) {
  padding: 0;
}
.toast-box {
  background: #ffffff;
  height: auto;

  .icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .icon-alert {
    border-right: 1px solid #ecedf3;
  }
  .text-title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.643rem;
    color: #595a5b;
  }
  .text-content {
    font-weight: 400;
    font-size: 0.857rem;
    line-height: 1.214rem;
    color: #7b7b87;
    text-align: left;
  }
  .confirm-button {
    padding: 5px 15px;
    color: $white;
    background: #fba45b;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.643rem;
  }
}
</style>
