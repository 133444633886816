import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import locales from "@/locales/locales";
import { SETTINGS } from "@/config/app.config";
import { camelCase, lowerFirst } from "lodash-es";

Vue.use(Vuetify);

const iconComponentFiles = import.meta.globEager("@/components/icons/*.vue");

let iconsImports = {};
Object.entries(iconComponentFiles).forEach(([path, m]) => {
  const componentName = lowerFirst(
    camelCase(
      path
        .split("/")
        .pop()
        .replace(/\.\w+$/, ""),
    ),
  );

  const iconComponent = {
    [componentName]: {
      component: m.default,
    },
  };

  iconsImports = { ...iconsImports, ...iconComponent };
});

const opts = {
  lang: {
    locales: locales,
    current: SETTINGS?.locale || "en",
  },
  theme: {
    themes: {
      light: {
        primary: "#417ce9",
        error: "#FF6C69",
        gray: "#666",
      },
    },
  },
  icons: {
    values: {
      ...iconsImports,
      searchPlacehoder: {
        component: () => import("@/components/icons/IconSearch.vue"),
        props: {
          activeColor: false,
        },
      },
    },
  },
  breakpoint: {
    mobileBreakpoint: 1200,
    thresholds: {
      xs: 0,
      sm: 768,
      md: 1200,
      lg: 1336,
      xl: 1600,
    },
  },
};
export default new Vuetify(opts);
