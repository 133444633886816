<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {{ $parent.$attrs["active"] }}
    <template v-if="active || $parent.$attrs['active']">
      <rect
        x="5.625"
        y="4.5"
        width="15.75"
        height="19.125"
        rx="2"
        stroke="url(#paint0_linear_3995_10688)"
        stroke-width="1.5"
      />
      <path
        d="M10.125 10.125H16.875"
        stroke="url(#paint1_linear_3995_10688)"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M10.125 14.625H16.875"
        stroke="url(#paint2_linear_3995_10688)"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M10.125 19.125H14.625"
        stroke="url(#paint3_linear_3995_10688)"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3995_10688"
          x1="13.5"
          y1="4.5"
          x2="13.5"
          y2="23.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#437BE8" />
          <stop offset="1" stop-color="#0F8DFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3995_10688"
          x1="13.5"
          y1="10.125"
          x2="13.5"
          y2="11.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#437BE8" />
          <stop offset="1" stop-color="#0F8DFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3995_10688"
          x1="13.5"
          y1="14.625"
          x2="13.5"
          y2="15.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#437BE8" />
          <stop offset="1" stop-color="#0F8DFB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3995_10688"
          x1="12.375"
          y1="19.125"
          x2="12.375"
          y2="20.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#437BE8" />
          <stop offset="1" stop-color="#0F8DFB" />
        </linearGradient>
      </defs>
    </template>
    <template v-else>
      <rect
        x="5.625"
        y="4.5"
        width="15.75"
        height="19.125"
        rx="2"
        stroke="#666666"
        stroke-width="1.5"
      />
      <path
        d="M10.125 10.125H16.875"
        stroke="#666666"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M10.125 14.625H16.875"
        stroke="#666666"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M10.125 19.125H14.625"
        stroke="#666666"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </template>
  </svg>
</template>
<script setup>
defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});
</script>
<style lang="scss" scoped>
.icon-package svg {
  width: 6.732rem;
  height: 6.964rem;
}
</style>
