export const button = {
  cancel: "Cancel",
  save: "Save",
  update: "Update",
  register: "Register",
  goToLogin: "Go to Log in",
  goToPartnerPortal: "Go to Partner Portal",
  buttonEdit: "Edit",
  buttonMenu: "Menu",
  buttonDelete: "Delete",
  confirm: "Confirm",
  export: "Export",
  filters: "Filters",
  submit: "Submit",
  clearAll: "Clear all",
  showResults: "Show results",
  create: "Create",
  send: "Send",
  close: "Close",
  search: "Search",
  viewMenu: "View menu",
  printMenu: "Print menu",
};
