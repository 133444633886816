export default {
  login: "Login",
  titleLogin: "Welcome to Partner Portal",
  userNameField: "Email address",
  passwordField: "Password",
  rememberMe: "Remember Me",
  forgotPassword: "Forgot Password ?",
  signin: "Log in",
  register: "Register",
  errorMessage:
    "The email and password you entered did not match our records.\nPlease double check and try again.",
};
