export default {
  help: "ช่วยเหลือ",
  nameOfHowTo: "Name Of How To",
  loremIpsum: "Lorem ipsum is simply text of the printing and typeset.",
  support: "ฝ่าย Support",
  supportChannels: "ช่องทางการสนับสนุน",
  hhForBusiness: "@HHforbusiness",
  addLine: "เพิ่ม LINE",
  supportHungryHub: "support@hungryhub.com",
  sendEmail: "ส่งอีเมล",
  seeMore: "ดูเพิ่มเติม",
};
