export default {
  accountManagement: "การจัดการบัญชี",
  profile: "โปรไฟล์",
  store: "ร้านค้า",
  userAccessRight: "สิทธิของผู้ใช้และการเข้าถึง",
  status: "สถานะ",
  generalInformation: "ข้อมูลทั่วไป",
  firstName: "ชื่อ",
  lastName: "นามสกุล",
  email: "อีเมล",
  role: "หน้าที่",
  contactNumber: "เบอร์ติดต่อ",
  changePassword: "เปลี่ยนรหัสผ่าน",
  oldPassword: "รหัสผ่านเก่า",
  newPassword: "รหัสผ่านใหม่",
  cancel: "Cancel",
  update: "อัปเดต",
  phone: "Phone",
  userId: "User ID",
  name: "ชื่อ",
  createdAt: "สร้างเมื่อ",
  lastOnline: "ออนไลน์ล่าสุด",
  action: "Action",
  owner: "เจ้าของ",
  contributor: "Contributor",
  viewer: "Viewer",
  lastSeen: "เข้าระบบครั้งสุดท้าย",
  joined: "เข้าร่วมเมื่อ",
  createANewUser: "สร้างผู้ใช้ใหม่",
  password: "รหัสผ่าน",
  undo: "ย้อนกลับ",
  confirmPassword: "ยืนยันรหัสผ่าน",
  createNewUser: "สร้างผู้ใช้ใหม่",
  create: "สร้าง",
  resetPassword: "รีเซ็ตรหัสผ่าน",
  receiveLinkResetPassword:
    "User will receive a link to reset password via email.",
  editAccessRight: "Do you want to edit access right",
  confirmDeleteAccount: "คุณต้องการลบบัญชีใช่หรือไม่",
  editUser: "แก้ไขข้อมูล",
  confirmDeleteImage: "คุณแน่ใจว่าต้องการลบรูปภาพนี้หรือไม่",
};
