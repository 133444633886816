<script setup>
import { onBeforeMount } from "vue";
import { useVuetify } from "@/composables/vuetify.composable";
import { isDevelopment, storageSwitcher } from "@/utils/storage.util";
import EnvSwitcher from "@/components/env-switcher/EnvSwitcher.vue";

const vuetify = useVuetify();

onBeforeMount(() => {
  const storageLocale =
    window.localStorage.getItem("locale") || vuetify.lang.current;
  vuetify.lang.current = storageLocale;

  if (!isDevelopment) {
    storageSwitcher();
  }
});
</script>

<template>
  <v-app id="app">
    <router-view />

    <ToastMessage />
    <EnvSwitcher v-if="isDevelopment" />
  </v-app>
</template>

<style lang="scss">
@import "@/assets/scss/app.scss";
</style>
