<template>
  <svg
    v-if="active || $parent.$attrs['active']"
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M91 3.25H13C12.569 3.25 12.1557 3.4212 11.851 3.72595C11.5462 4.0307 11.375 4.44402 11.375 4.875V13H8.125C7.26305 13 6.4364 13.3424 5.8269 13.9519C5.21741 14.5614 4.875 15.388 4.875 16.25V22.75C4.875 23.612 5.21741 24.4386 5.8269 25.0481C6.4364 25.6576 7.26305 26 8.125 26H11.375V32.5H8.125C7.26305 32.5 6.4364 32.8424 5.8269 33.4519C5.21741 34.0614 4.875 34.888 4.875 35.75V42.25C4.875 43.112 5.21741 43.9386 5.8269 44.5481C6.4364 45.1576 7.26305 45.5 8.125 45.5H11.375V58.5H8.125C7.26305 58.5 6.4364 58.8424 5.8269 59.4519C5.21741 60.0614 4.875 60.888 4.875 61.75V68.25C4.875 69.112 5.21741 69.9386 5.8269 70.5481C6.4364 71.1576 7.26305 71.5 8.125 71.5H11.375V78H8.125C7.26305 78 6.4364 78.3424 5.8269 78.9519C5.21741 79.5614 4.875 80.388 4.875 81.25V87.75C4.875 88.612 5.21741 89.4386 5.8269 90.0481C6.4364 90.6576 7.26305 91 8.125 91H11.375V99.125C11.375 99.556 11.5462 99.9693 11.851 100.274C12.1557 100.579 12.569 100.75 13 100.75H91C93.1541 100.747 95.2192 99.8906 96.7424 98.3674C98.2656 96.8442 99.1224 94.7791 99.125 92.625V11.375C99.1224 9.22091 98.2656 7.15578 96.7424 5.63261C95.2192 4.10943 93.1541 3.25258 91 3.25ZM8.125 22.75V16.25H11.375V22.75H8.125ZM8.125 42.25V35.75H11.375V42.25H8.125ZM8.125 68.25V61.75H11.375V68.25H8.125ZM8.125 87.75V81.25H11.375V87.75H8.125ZM14.625 6.5H17.875V97.5H14.625V6.5ZM95.875 92.625C95.875 93.9179 95.3614 95.1579 94.4471 96.0721C93.5329 96.9864 92.2929 97.5 91 97.5H21.125V6.5H91C92.2929 6.5 93.5329 7.01361 94.4471 7.92785C95.3614 8.84209 95.875 10.0821 95.875 11.375V92.625Z"
      fill="url(#paint0_linear_1067_6039)"
    />
    <path
      d="M78 14.625C73.6917 14.6297 69.5612 16.3433 66.5147 19.3897C63.4683 22.4362 61.7547 26.5667 61.75 30.875V50.375C61.75 50.806 61.9212 51.2193 62.226 51.524C62.5307 51.8288 62.944 52 63.375 52H68.25C68.681 52 69.0943 52.1712 69.399 52.476C69.7038 52.7807 69.875 53.194 69.875 53.625V78C69.875 78.431 70.0462 78.8443 70.351 79.149C70.6557 79.4538 71.069 79.625 71.5 79.625H78C78.431 79.625 78.8443 79.4538 79.149 79.149C79.4538 78.8443 79.625 78.431 79.625 78V16.25C79.625 15.819 79.4538 15.4057 79.149 15.101C78.8443 14.7962 78.431 14.625 78 14.625ZM76.375 76.375H73.125V53.625C73.125 52.3321 72.6114 51.0921 71.6971 50.1779C70.7829 49.2636 69.5429 48.75 68.25 48.75H65V30.875C65.0042 27.7097 66.1613 24.6543 68.2549 22.2802C70.3484 19.9061 73.2351 18.3759 76.375 17.9758V76.375Z"
      fill="url(#paint1_linear_1067_6039)"
    />
    <path
      d="M40.625 48.7159V78C40.625 78.431 40.7962 78.8443 41.101 79.149C41.4057 79.4538 41.819 79.625 42.25 79.625H48.75C49.181 79.625 49.5943 79.4538 49.899 79.149C50.2038 78.8443 50.375 78.431 50.375 78V48.7159C51.3795 47.9625 52.1957 46.9866 52.7596 45.8647C53.3235 44.7429 53.6197 43.5056 53.625 42.25V16.25H50.375V27.625H47.125V16.25H43.875V27.625H40.625V16.25H37.375V42.25C37.3803 43.5056 37.6765 44.7429 38.2404 45.8647C38.8043 46.9866 39.6205 47.9625 40.625 48.7159ZM50.375 42.25C50.3757 43.109 50.1466 43.9526 49.7114 44.6932C49.2763 45.4338 48.6509 46.0445 47.9001 46.462C47.6132 46.6277 47.3846 46.8782 47.2458 47.1791C47.107 47.4799 47.0648 47.8164 47.125 48.1423V76.375H43.875V48.2495C43.9563 47.9078 43.9248 47.549 43.785 47.2267C43.6453 46.9045 43.4049 46.6362 43.0999 46.462C42.3491 46.0445 41.7237 45.4338 41.2886 44.6932C40.8534 43.9526 40.6243 43.109 40.625 42.25V30.875H50.375V42.25Z"
      fill="url(#paint2_linear_1067_6039)"
    />
    <path d="M78 87.75H39V91H78V87.75Z" fill="url(#paint3_linear_1067_6039)" />
    <defs>
      <linearGradient
        id="paint0_linear_1067_6039"
        x1="45.3597"
        y1="-24.2273"
        x2="127.123"
        y2="-4.46777"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4478FF" />
        <stop offset="1" stop-color="#45B2FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1067_6039"
        x1="69.4281"
        y1="-3.69318"
        x2="85.7634"
        y2="-2.57012"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4478FF" />
        <stop offset="1" stop-color="#45B2FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1067_6039"
        x1="44.3551"
        y1="-1.61022"
        x2="59.2145"
        y2="-0.657696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4478FF" />
        <stop offset="1" stop-color="#45B2FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1067_6039"
        x1="55.7523"
        y1="86.8341"
        x2="59.3332"
        y2="97.5768"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4478FF" />
        <stop offset="1" stop-color="#45B2FF" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    v-else
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M91 3.25H13C12.569 3.25 12.1557 3.4212 11.851 3.72595C11.5462 4.0307 11.375 4.44402 11.375 4.875V13H8.125C7.26305 13 6.4364 13.3424 5.8269 13.9519C5.21741 14.5614 4.875 15.388 4.875 16.25V22.75C4.875 23.612 5.21741 24.4386 5.8269 25.0481C6.4364 25.6576 7.26305 26 8.125 26H11.375V32.5H8.125C7.26305 32.5 6.4364 32.8424 5.8269 33.4519C5.21741 34.0614 4.875 34.888 4.875 35.75V42.25C4.875 43.112 5.21741 43.9386 5.8269 44.5481C6.4364 45.1576 7.26305 45.5 8.125 45.5H11.375V58.5H8.125C7.26305 58.5 6.4364 58.8424 5.8269 59.4519C5.21741 60.0614 4.875 60.888 4.875 61.75V68.25C4.875 69.112 5.21741 69.9386 5.8269 70.5481C6.4364 71.1576 7.26305 71.5 8.125 71.5H11.375V78H8.125C7.26305 78 6.4364 78.3424 5.8269 78.9519C5.21741 79.5614 4.875 80.388 4.875 81.25V87.75C4.875 88.612 5.21741 89.4386 5.8269 90.0481C6.4364 90.6576 7.26305 91 8.125 91H11.375V99.125C11.375 99.556 11.5462 99.9693 11.851 100.274C12.1557 100.579 12.569 100.75 13 100.75H91C93.1541 100.747 95.2192 99.8906 96.7424 98.3674C98.2656 96.8442 99.1224 94.7791 99.125 92.625V11.375C99.1224 9.22091 98.2656 7.15578 96.7424 5.63261C95.2192 4.10943 93.1541 3.25258 91 3.25ZM8.125 22.75V16.25H11.375V22.75H8.125ZM8.125 42.25V35.75H11.375V42.25H8.125ZM8.125 68.25V61.75H11.375V68.25H8.125ZM8.125 87.75V81.25H11.375V87.75H8.125ZM14.625 6.5H17.875V97.5H14.625V6.5ZM95.875 92.625C95.875 93.9179 95.3614 95.1579 94.4471 96.0721C93.5329 96.9864 92.2929 97.5 91 97.5H21.125V6.5H91C92.2929 6.5 93.5329 7.01361 94.4471 7.92785C95.3614 8.84209 95.875 10.0821 95.875 11.375V92.625Z"
      fill="#AFAFBE"
    />
    <path
      d="M78 14.625C73.6917 14.6297 69.5612 16.3433 66.5147 19.3897C63.4683 22.4362 61.7547 26.5667 61.75 30.875V50.375C61.75 50.806 61.9212 51.2193 62.226 51.524C62.5307 51.8288 62.944 52 63.375 52H68.25C68.681 52 69.0943 52.1712 69.399 52.476C69.7038 52.7807 69.875 53.194 69.875 53.625V78C69.875 78.431 70.0462 78.8443 70.351 79.149C70.6557 79.4538 71.069 79.625 71.5 79.625H78C78.431 79.625 78.8443 79.4538 79.149 79.149C79.4538 78.8443 79.625 78.431 79.625 78V16.25C79.625 15.819 79.4538 15.4057 79.149 15.101C78.8443 14.7962 78.431 14.625 78 14.625ZM76.375 76.375H73.125V53.625C73.125 52.3321 72.6114 51.0921 71.6971 50.1779C70.7829 49.2636 69.5429 48.75 68.25 48.75H65V30.875C65.0042 27.7097 66.1613 24.6543 68.2549 22.2802C70.3484 19.9061 73.2351 18.3759 76.375 17.9758V76.375Z"
      fill="#AFAFBE"
    />
    <path
      d="M40.625 48.7159V78C40.625 78.431 40.7962 78.8443 41.101 79.149C41.4057 79.4538 41.819 79.625 42.25 79.625H48.75C49.181 79.625 49.5943 79.4538 49.899 79.149C50.2038 78.8443 50.375 78.431 50.375 78V48.7159C51.3795 47.9625 52.1957 46.9866 52.7596 45.8647C53.3235 44.7429 53.6197 43.5056 53.625 42.25V16.25H50.375V27.625H47.125V16.25H43.875V27.625H40.625V16.25H37.375V42.25C37.3803 43.5056 37.6765 44.7429 38.2404 45.8647C38.8043 46.9866 39.6205 47.9625 40.625 48.7159ZM50.375 42.25C50.3757 43.109 50.1466 43.9526 49.7114 44.6932C49.2763 45.4338 48.6509 46.0445 47.9001 46.462C47.6132 46.6277 47.3846 46.8782 47.2458 47.1791C47.107 47.4799 47.0648 47.8164 47.125 48.1423V76.375H43.875V48.2495C43.9563 47.9078 43.9248 47.549 43.785 47.2267C43.6453 46.9045 43.4049 46.6362 43.0999 46.462C42.3491 46.0445 41.7237 45.4338 41.2886 44.6932C40.8534 43.9526 40.6243 43.109 40.625 42.25V30.875H50.375V42.25Z"
      fill="#AFAFBE"
    />
    <path d="M78 87.75H39V91H78V87.75Z" fill="#AFAFBE" />
  </svg>
</template>
<script setup>
defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});
</script>
<style lang="scss" scoped>
.icon-package svg {
  width: 6.732rem;
  height: 6.964rem;
}
</style>
