export const voucherRouter = [
  {
    path: "/vouchers",
    name: "voucher",
    component: () => import("../pages/voucher/voucher-list/VoucherList.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.voucherList",
      parent: {
        name: "$vuetify.layout.navigationSidebar.voucher",
        disabled: false,
      },
    },
  },
  {
    path: "/vouchers/history",
    name: "voucher",
    component: () =>
      import("../pages/voucher/voucher-history/VoucherHistory.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.voucherHistory",
      parent: {
        name: "$vuetify.layout.navigationSidebar.voucher",
        disabled: false,
        path: "/vouchers",
        exact: true,
      },
    },
  },
  {
    path: "/vouchers/create",
    name: "Create Voucher",
    component: () =>
      import("../pages/voucher/create-voucher/CreateVoucher.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.createVoucher",
      parent: {
        name: "$vuetify.layout.navigationSidebar.voucher",
        disabled: false,
        path: "/vouchers",
        exact: true,
      },
    },
  },
  {
    path: "/vouchers/edit/:id",
    name: "Edit Voucher",
    component: () => import("../pages/voucher/edit-voucher/EditVoucher.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.editVoucher",
      parent: {
        name: "$vuetify.layout.navigationSidebar.voucher",
        disabled: false,
        path: "/vouchers",
        meta: {
          text: "$vuetify.layout.navigationSidebar.voucherList",
          exact: true,
          parent: {
            name: "$vuetify.layout.navigationSidebar.voucher",
            disabled: false,
            path: "/vouchers",
            exact: true,
          },
        },
      },
    },
  },
];
