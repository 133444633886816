import request from "@/utils/request.util";

/**
 * Login by email and password
 *
 * @param {String} email user email
 * @param {String} password user password
 */
export const loginByEmail = async (payload) => {
  return request.post("sign_in", payload);
};

/**
 * Get user information by token
 *
 * @param {String} token user token
 */
export const getUserInfo = async (payload) => {
  const params = {
    restaurant_id: payload.restaurant_id,
  };
  return request.get("staffs/show_profile", {
    headers: {
      Authorization: payload.token,
    },
    params,
  });
};

/**
 * Forgot password
 *
 * @param {String} email user email
 * @param {String} password user password
 */
export const forgotPassword = async (payload) => {
  return request.post("staffs/forgot_password", payload);
};

/**
 * Reset new password
 *
 * @param {String} email user email
 * @param {String} password user password
 */
export const resetNewPassword = async (payload) => {
  return request.post("staffs/reset_password", payload);
};

/**
 * GET mongodb auth token
 *
 */
export const getChartAuthToken = async (payload) => {
  return request.get("staffs/get_mongodb_auth_token", payload);
};
