export default {
  pendingArrival: "Pending Arrival",
  arrived: "Arrived",
  cancelled: "Cancelled",
  noShow: "No Show",
  bookingCompleted: "Booking completed",
  noDriverYet: "No driver yet",
  driverOnTheWayToRestaurant: "Driver on the way to restaurant",
  driverIsOnTheWayToCustomer: "Driver is on the way to Customer",
  DriverHasArrived: "Driver has Arrived",
  deliveryCompleted: "delivery completed",
  orderReceived: "order received",
  yourOrderInTheKitchen: "your order in the kitchen",
  WaitingForPickUp: "Waiting for pick up",
  orderCompleted: "Order completed",
};
