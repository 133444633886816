export default {
  export: "ดึงรายงาน",
  reportType: "ประเภทรายงาน",
  excel: "Excel",
  pdf: "ไฟล์ PDF",
  filterBy: "กรองโดย",
  rangeDate: "หลายวัน",
  singleDate: "วันเดียว",
  startDate: "วันที่เริ่มต้น",
  endDate: "วันที่สิ้นสุด",
  emailAddress: "อีเมล",
  date: "วันที่",
  startTime: "เวลาเริ่มต้น",
  endTime: "เวลาสิ้นสุด",
  exportBilling: "ดึงรายงานการเรียกเก็บเงิน",
  month: "เดือน",
  year: "ปี",
  dateType: "ประเภทวันที่",
  createdDate: "วันที่สร้าง",
  diningDate: "วันที่รับประทานอาหาร",
};
