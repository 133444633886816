export const help = {
  help: "Help",
  nameOfHowTo: "Name Of How To",
  loremIpsum: "Lorem ipsum is simply text of the printing and typeset.",
  support: "Support",
  supportChannels: "Support Channels",
  hhForBusiness: "@HHforbusiness",
  addLine: "Add LINE",
  supportHungryHub: "support@hungryhub.com",
  sendEmail: "Send Email",
  seeMore: "See More",
};
