export default {
  greatSuccess: "Great success!",
  yikesSomethingWentWrong: "Yikes, Something went wrong.",
  // package
  packageCreateSuccess: "The package was successfully created.",
  packageEditSuccess: "The package was successfully edited.",
  packageDeleteSuccess: "The package items was successfully deleted.",
  packageUpdateSuccess: "The package items was successfully updated.",
  dupticateSuccess: "Dupticate success",
  deletePackageSuccess: "Delete package success",
  tooManyPackages: "Too Many Packages!",
  packagemaximum100:
    "Package maximum is 100, please delete other package then try again.",
  //voucher
  voucherCreateSuccess: "The voucher was successfully created.",
  vouchercodesuccessfullyRedeemed: "The voucher code successfully redeemed.",
  guestsuccessfullyupdated: "Guest details successfully updated.",
  // review management
  sentReviewSuccess: "Sent review successfully!",

  // analytic
  systemwillsendemail:
    "System will send you an email to download the performance review.",
  tryagainlater:
    "We’re sorry that you have to experience some problems! Please try again later.",
  // account management
  storeUpdatedSuccess: "The store was updated successfully.",
  dropPhoTo: "Drop Photo Here To Upload",
  chooseImage: "Choose Image",
  maximumUpload: "Maximum Upload File Size 5 MB.",
};
