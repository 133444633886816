import router from "@/router";
import { SYSTEM_CONFIG } from "@/config/app.config";
import { useUserStore } from "@/stores/user.store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { createTitlePage } from "@/utils/layout.util";

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();

  // Start progress bar
  NProgress.start();

  if (handleSpecialQueryParams(to, userStore, next)) {
    return;
  }

  if (userStore.getToken) {
    await handleAuthenticatedUser(userStore, to, next);
  } else {
    handleUnauthenticatedUser(to, next);
  }
});

router.afterEach((to) => {
  // title page on side bar
  createTitlePage(to);
  NProgress.done();
});

function handleSpecialQueryParams(to, userStore, next) {
  if (to.query.reset_password_token) {
    userStore.logOut();
  }

  if (to.query.language) {
    window.localStorage.setItem("locale", to.query.language);
  }

  if (to.query.token) {
    return handleTokenLogin(to, userStore, next);
  }

  return false;
}

async function handleTokenLogin(to, userStore, next) {
  try {
    const fetchUserInfo = await userStore.getUserInfo(to.query.token);
    if (fetchUserInfo) {
      next({ path: to.path });
      NProgress.done();
      return true;
    }
  } catch (e) {
    console.warn("Login via token denied!");
    next(`/login`);
    NProgress.done();
    return true;
  }

  return false;
}

async function handleAuthenticatedUser(userStore, to, next) {
  if (SYSTEM_CONFIG.deniedListsUrl.includes(to.path)) {
    next({ path: "/" });
    NProgress.done();
    return;
  }

  await ensureUserIsLoaded(userStore);

  if (userStore.getUser) {
    next();
  } else {
    handleUserLoadFailure(to, next);
  }
}

async function ensureUserIsLoaded(userStore) {
  if (!userStore.getUser) {
    try {
      await userStore.getUserInfo();
    } catch (e) {
      console.log("permission::get user details", e);
    }
  }
}

function handleUserLoadFailure(to, next) {
  next(`/login?redirect=${to.path}`);
  window.localStorage.clear();
  window.location.reload();
}

function handleUnauthenticatedUser(to, next) {
  if (SYSTEM_CONFIG.whiteListsUrl.includes(to.path)) {
    next();
  } else {
    next(`/login?redirect=${to.path}`);
    NProgress.done();
  }
}
