import LoginPage from "./login";
import ForgotPasswordDialog from "./forgotPassword";
import layout from "./layout";
import ResetPassword from "./resetPassword";
import SuccessDialog from "./successDialog";
import ValidationForm from "./validationForm";
import { dashboard } from "./dashboard";
import { help } from "./help";
import reviewManangement from "./reviewManangement";
import register from "./register";
import booking from "./booking";
import chipContent from "./chipContent";
import { button } from "./button";
import packagePage from "./package";
import voucher from "./voucher";
import billing from "./billing";
import accountManagement from "./accountManagement";
import menu from "./menu";
import ranking from "./ranking";
import analyticsPerformance from "./analyticsPerformance";
import allotments from "./allotments";
import documents from "./documents";
import marketing from "./marketing";
import exportPage from "./exportPage";
import filterPage from "./filterPage";
import toast from "./toast";
import selectBranch from "./selectBranch";

export default {
  title: "Portal Partner",
  login: LoginPage,
  forgotPassword: ForgotPasswordDialog,
  layout,
  resetPassword: ResetPassword,
  successDialog: SuccessDialog,
  validationForm: ValidationForm,
  dashboard: dashboard,
  help: help,
  register: register,
  reviewManangement: reviewManangement,
  booking: booking,
  chipContent: chipContent,
  button: button,
  package: packagePage,
  voucher: voucher,
  billing: billing,
  ranking: ranking,
  accountManagement: accountManagement,
  menu: menu,
  analyticsPerformance: analyticsPerformance,
  allotments: allotments,
  documents: documents,
  marketing: marketing,
  exportPage: exportPage,
  filterPage: filterPage,
  toast,
  selectBranch,
};
