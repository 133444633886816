export default {
  title:
    "Please upload documents required for Hungry Hub Partner Account Verification",
  a_copy_of_bankbook_account: "A copy of Bankbook account",
  a_copy_of_identification_card: "A copy of Identification card",
  a_signed_e_contract: `A signed E-contract`,
  please_download: `&nbsp;(Please download <a href="{0}" target="_blank" class="link-contract ma-0">here</a>)`,
  certificate_dbd: "Company Certificate (DBD) Valid for 90 days",
  certificate_pp20: "The Certificate of value added tax registration (PP20)",
  dbd: "According to Company Certificate (DBD), the authorizer is required to sign “copyright” and stamp company’s seal on copy of documents.",
  power_of_attorney_document:
    "The power of attorney document is required if there is right transfer for others apart from committee to sign.",
  corporation: "Corporation",
  soleProprietorship: "Sole Proprietorship",
  theDocuments: "The documents was successfully updated.",
};
