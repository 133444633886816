export default {
  title: "ลืมรหัสผ่าน?",
  textContent:
    "โปรดป้อนที่อยู่อีเมลที่ลงทะเบียนในบัญชีของคุณ คุณจะได้รับลิงค์เพื่อสร้างรหัสผ่านใหม่",
  emailField: "อีเมล",
  button: "รีเซ็ตรหัสผ่าน",
  dialog: {
    success: {
      heading: "ตรวจสอบอีเมลของคุณ",
      body: "เราได้ส่งคำสั่งเพื่อตั้งรหัสผ่านใหม่ไปยังอีเมลของคุณ",
      footer: "หากไม่ได้รับอีเมล กรุณาเช็คในกล่อง spam",
    },
  },
};
