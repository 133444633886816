export default {
  export: "Export",
  reportType: "Report Type",
  excel: "Excel",
  pdf: "PDF",
  filterBy: "Filter By",
  rangeDate: "Range Date",
  singleDate: "Single Date",
  startDate: "Start Date",
  endDate: "End Date",
  emailAddress: "Email Address",
  date: "Date",
  startTime: "Start Time",
  endTime: "End Time",
  exportBilling: "Export Billing",
  month: "Month",
  year: "Year",
  dateType: "Date Type",
  createdDate: "Created Date",
  diningDate: "Dining Date",
};
