import axios from "axios";
import { APP_BASE_API_URL } from "@/config/app.config";
import { useUserStore } from "@/stores/user.store";
import { isProduction } from "@/utils/enviroment.util";
import vuetify from "@/plugins/vuetify";
import qs from "qs";

// axios.defaults.withCredentials = true;
const request = axios.create({
  baseURL: APP_BASE_API_URL,
});

// Request interceptor
request.interceptors.request.use(
  (request) => {
    const userStore = useUserStore();
    const currentLanguage = vuetify?.framework?.lang?.current;

    console.log(`Request.util::Current language: `, currentLanguage);
    request.headers = {
      "X-HH-Language": currentLanguage,
      "Content-Type": "application/json",
      ...(userStore.token ? { Authorization: userStore.token } : {}),
      ...(request.headers || {}),
    };

    if (request.method === "get") {
      request.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }

    const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf("MSIE "); // IE 10 or older
    const trident = ua.indexOf("Trident/"); //IE 11
    if (msie > 0 || trident > 0) {
      // If Internet Explorer, return version number
      request.headers.common["Pragma"] = "no-cache";
    }

    return request;
  },
  (err) => {
    return Promise.reject(err);
  },
);

request.interceptors.response.use(
  function (res) {
    if (res.request.responseType === "blob") {
      return res;
    }

    if (!isProduction) {
      console.log("Request util Response: ", res);
    }

    return res.data;
  },
  function (err) {
    const userStore = useUserStore();
    if (err?.response?.status === 401) {
      userStore.logOut();
      window.location.href = "/login";
    }
    if (err?.response?.data) return Promise.reject(err?.response?.data);
    return Promise.reject(err);
  },
);

export default request;
