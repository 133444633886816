export default {
  title: "โปรดอัปโหลดเอกสารที่จำเป็นสำหรับการยืนยันบัญชีพาร์ทเนอร์ Hungry Hub",
  a_copy_of_bankbook_account: "สำเนาบัญชีธนาคาร",
  a_copy_of_identification_card: "สำเนาบัตรประชาชน",
  a_signed_e_contract: "สัญญาออนไลน์ที่ลงนามแล้ว",
  please_download: `&nbsp;(<a href="{0}" target="_blank" class="link-contract ma-0">ดาวน์โหลดที่นี่</a>)`,
  certificate_dbd: "สำเนาใบจดทะเบียนรับรองบริษัท (ออกให้ไม่เกิน 3 เดือน)",
  certificate_pp20: "ใบรับรองการลงทะเบียนภาษีมูลค่าเพิ่ม (pp20)",
  dbd: "ผู้มีอำนาจจะต้องเซ็นรับรองสำเนาถูกต้องทุกฉบับ พร้อมประทับตราสำคัญบริษัท",
  power_of_attorney_document:
    "หากมีการมอบอำนาจให้ผู้อื่นเซ็นแทนจะต้องมีใบมอบอำนาจรับรอง",
  corporation: "นิติบุคคล",
  soleProprietorship: "บุคคลธรรมดา",
  theDocuments: "อัปเดทเอกสารของคุณสำเร็จแล้ว.",
};
