export default {
  title: "Lost your password? ",
  textContent:
    "Please enter the email address registered to your account. You will receive a link to create a new password.",
  emailField: "Email",
  button: "Reset Password",
  dialog: {
    success: {
      heading: "Check Your Email",
      body: "We have sent an instruction to set a new password to your email.",
      footer: "Did not receive the email? Check your spam filter.",
    },
  },
};
