import request from "@/utils/request.util";
import qs from "qs";

export const getProfileAccountManagement = async () => {
  return request.get("/staffs/show_profile");
};
export const updateProfileAccountManagement = async (payload) => {
  return request.put(`/staffs/update_profile`, payload);
};

// get view list user

export const getViewListUser = async (payload) => {
  // console.log('param',payload)
  const params = {
    params: payload,
  };
  return request.get("/staffs/", params);
};

export const deleteUser = async (id) => {
  return request.delete(`/staffs/${id}`);
};

// get role user
export const getRoleUser = async () => {
  return request.get("/staffs/new");
};

// create new user
export const createNewUser = async (payload) => {
  return request.post("/staffs", payload);
};

// update user
export const editUser = async (id, payload) => {
  return request.put(`/staffs/${id}`, payload);
};

// get view user
export const getViewEditUser = async (id) => {
  return request.get(`/staffs/${id}/edit`);
};

// reset password
export const resetPasswordUser = async (payload) => {
  return request.post("/staffs/forgot_password", payload);
};

// get list city
export const getListCity = async (payload = { restaurant_id: "1" }) => {
  const params = {
    restaurant_id: payload.restaurant_id,
  };
  const query = `${qs.stringify(params)}`;
  return request.get(`/cities?${query}`);
};

// get list districts
export const getListDistricts = async (payload) => {
  const params = {
    params: payload,
  };
  return request.get("/districts", params);
};

// get store detail
export const getStoreDetail = async (payload) => {
  const params = {
    restaurant_id: payload,
  };
  const query = `${qs.stringify(params)}`;
  return request.get(`/restaurants/view_store?${query}`);
};

export const updateStore = async (payload) => {
  return request.put("/restaurants/update_store", payload);
};

export const updateProgress = async (payload) => {
  return request.put("restaurants/update_steps_verify", payload);
};

export const getDocuments = async (payload) => {
  const params = {
    restaurant_id: payload.restaurant_id,
  };
  const query = `${qs.stringify(params)}`;
  return request.get(`restaurant_documents?${query}`);
};

export const updateDocuments = async (payload) => {
  return request.post("restaurants/create_documents", payload, {
    headers: { "content-type": "multipart/form-data" },
  });
};

export const reverseGeocode = async (payload) => {
  const params = {
    params: payload,
  };
  return request.get("restaurants/reverse_geocode", params);
};
