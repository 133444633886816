export default {
  layout: {
    welcome: "Welcome White Orchird Bistro, let's get you started!",
    title: "Would you like to sell Package or Voucher?",
    createPackage: "Create Package",
    createPackageText:
      "Types of packages that are only available on Hungry Hub. You will be setting up number of seatings to be opened on the system,menu and price for the package.",
    createVoucher: "Create Voucher",
    createVoucherText:
      "Voucher is a discount coupon, with privileges. Customers will book directly with our partner hotels and restaurants. You will be setting up the voucher details, quantity and price to start selling on our system.",
    getStateButton: "Get started",
  },
  logged: {
    galleryManagement: "Gallery Management",
    searchBuilding: "Search Building",
    pleaseChooseYourLocation: "Please choose your location",
    addMoreButton: "Add More",
    deleteButton: "Delete",
    textStep: "Step",
    textOf: "of",
    stepStoreSetting: "Store Setting",
    stepAllotmentManagement: "Allotment Management",
    stepPackage: "Package & Menu",
    stepDocument: "Document",
    supportTitle: "Question? Click help for support",
    textNoteVerify: "Please complete the steps to verify your account",
    textNoteVerifySuccess: "You will hear from us within 5-7 days",
    register: "Register",
    successVerifyTitle: "Thank you! Your account is in review!",
    buttonEditAndResubmit: "Edit info and resubmit",
    textComplete: "Complete!",
    restaurantDetailsFacilities: "Restaurant details, Place & facilities",
    hotelDetailsLocationFacilities: "Location & facilities",
    experienceDetailLocationFacilities: "Location & facilities",
    primary: "Primary",
    secondary: "Secondary",
    primaryCuisine: "Primary Cuisine",
    secondaryCuisine: "Secondary Cuisine",
    primaryDiningStyle: "Primary Dining Style",
    secondaryDiningStyle: "Secondary Dining Style",
    displayTag: "Display Tag",
    subTag: "Sub Tag",
    displayTagCuisine: "Display Tag Cuisine",
    subTagCuisine: "Sub Tag Cuisine",
    displayTagDiningStyle: "Display Tag Dining Style",
    subTagDiningStyle: "Sub Tag Dining Style",
    maxChoices: "Max {0} choices",
    seeMore: "See More",
    seeLess: "See Less",
    locationGmap: "Location in Google Map",
    city: "City",
    popularZone: "Popular zone",
    district: "District",
    shoppingMall: "Shopping Mall",
    btsRoute: "BTS Route",
    mrtRoute: "MRT Route",
    facilities: "Facilities",
    restaurantInformation: "Restaurant information",
    hotelInformation: "Hotel information",
    experienceInformation: "Experience information",
    mainContactPerson: "Main contact Person",
    country: "Country",
    currency: "Currency",
    pleaseContact: "Please contact Support if you wish to change your Country",
    shortNameEng: "Short Name (EN) For SMS",
    shortNameTha: "Short Name (TH) For SMS",
    character: "(20 characters limit)",
    primaryStorePhone: "Primary Store Phone No.",
    socialMedia: "Social Media & Website",
    secondaryStore: "Secondary Store Phone No.",
    name: "Name",
    role: "Role",
    phoneNumber: "Phone Number",
    emailAddress: "Email Address",
    stepcreateVoucher: "Create Voucher",
    hotelBrand: "Hotel Brand",
    starRating: "Star Rating",
    typeOfExperience: "Type of Experience",
    groupSize: "Group Size",
    typeOfVenue: "Type of Venue",
    pleaseExplainAbout: "Please explain about your food service",
    logo: "Logo",
    coverImage: "Cover image",
    addPhoto: "Add Photo",
    changePhoto: "Change Photo",
    dropPhoto: "Drop Photo Here To Upload",
    dropPhotoMessage: "*Maximum Upload File Size {0}.",
    yourPicture: "Your Picture",
    priority: "Priority",
    optional: "Optional",
    thumbnail: "Thumbnail",
    gallery: "Gallery",
    photomanagement: "Photo Management",
    cuisineAndDiningStyle: "Cuisine & Dining Style",
    cuisine: "Cuisine",
    diningStyle: "Dining Style",
    location: "Location",
    place: "Place",
    specifyName: `Please specify the name (s)`,
    specifyLink: `Please specify the handle or the link`,
    onSiteRestaurant: `
    <span class="d-block"> On-site</span>
    <span class="d-block">
      Restaurants
      <span class="red--text">*</span>
    </span>`,
    availableRoom: `<span class="d-block"> Available</span>
    <span class="d-block">
      Rooms
      <span class="red--text">*</span>
    </span>`,
    averageRoomRate: `
    <span class="d-block"> Average </span>
    <span class="d-block">
      Room Rate
      <span class="red--text">*</span>
    </span>`,
  },
  form: {
    howdid: "How did you hear",
    hear: "about Hungry Hub?",
    howDidHear: "How did you hear about Hungry Hub?",
    title: "Register",
    aboutYourOutlet: "About Your Outlet",
    outletType: "Outlet Type",
    pleaseSelectOne: "Please select one",
    country: "Country",
    city: "City",
    select: "Select",
    name: "Name",
    primaryPhoneNo: "Primary Phone No.",
    operatingHours: `<span class="d-block">Operating Hours</span><span class="d-block">(Open - Close)
    <span class="red--text">*</span></span>`,
    LocationInGoogleMap: "Location in Google Map",
    description: "Description",
    en: "(EN)",
    th: "(TH)",
    primaryCuisine: "Primary Cuisine",
    numberOfBranch: "Number of Branch",
    es: "(es)",
    anyNearLandmark: "Any Near Landmark?",
    restaurant: "Restaurant",
    hotel: "Hotel",
    experience: "Experience",
    lorem:
      "— Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
    howDoWeContactYou: "How Do We Contact You?",
    yourName: "Your Name",
    yourPhoneNumber: "Your Phone Number",
    yourRole: "Your Role",
    yourEmailAddress: "Your Email Address",
    howDidYouHearAboutHungryHub: "How did you hear about Hungry Hub?",
    accountInformation: "Account Information",
    youWillUseThis: "You will use this information to log in to Partner Portal",
    emailAddress: "Email Address",
    password: "Password",
    confirmPassword: "Confirm Password",
    thankYouPleaseCheck:
      "Thank You! Please Check Your Email For Log In Information.",
    verified: "Verified!",
    youHaveSuccessfully: "You have successfully verified the account.",
    charactersLimit: "*5000 characters limit",
    tooltips: {
      restaurant:
        "select this option if you are a restaurant or a restaurant in a hotel.",
      hotel:
        "select this option if you are a hotel and wants to open the system for room booking.",
      experience: "select this option if you provide an experience.",
    },
  },
};
