import request from "@/utils/request.util";
import qs from "qs";

export const getListPackage = async (payload) => {
  const params = {
    params: payload,
  };
  return request.get("/restaurant_packages", params);
};

export const createNewPackage = async (payload) => {
  return request.post("/restaurant_packages", payload);
};

export const changeStatusPackage = async (payload) => {
  return request.post("/restaurant_packages/change_active", payload);
};

export const exportListPackage = async (payload) => {
  return request.post("/restaurant_packages/export", payload);
};

export const duplicatePackage = async (payload) => {
  return request.post(`/restaurant_packages/duplicate`, payload);
};

export const deletePackage = async (id) => {
  return request.delete(`/restaurant_packages/${id}`);
};

export const deleteAllPackageItem = async () => {
  return request.delete("restaurant_packages/destroy_all");
};

export const getPaymentType = async () => {
  return request.get("/restaurant_packages/payment_types");
};

export const getPackageDetails = async ({ id, payload }) => {
  const params = {
    restaurant_id: payload.restaurant_id,
  };
  const query = `${qs.stringify(params)}`;
  return request.get(`/restaurant_packages/${id}?${query}`);
};

export const updatePackage = async (id, payload) => {
  return request.put("/restaurant_packages/" + id, payload, {
    headers: { "content-type": "multipart/form-data" },
  });
};

export const getMenuV2PreviewLink = async (link) => {
  const axios = await import("axios");
  return axios.get(link);
};
