import Vue from "vue";

import App from "./App.vue";
import pinia from "./stores";
import router from "./router";
import { loadFonts } from "@/plugins/webfontloader";
import vuetify from "@/plugins/vuetify";
import rollbar from "@/plugins/rollbar";
import "@/middleware/permission";

loadFonts();

Vue.prototype.$rollbar = rollbar;
Vue.config.errorHandler = (err, vm) => {
  vm.$rollbar.error(err);
  throw err;
};

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
