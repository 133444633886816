export const packageRouter = [
  {
    path: "/packages",
    name: "package",
    component: () => import("../pages/package/PackageList.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.package",
    },
  },
  {
    path: "/packages/create/:id?",
    name: "create-package",
    component: () =>
      import("../pages/package/create-package/CreatePackage.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.createPackage",
      parent: {
        name: "$vuetify.layout.navigationSidebar.package",
        disabled: false,
        path: "/packages",
        exact: true,
      },
    },
  },
  // {
  //   path: "/packages/create/:id/menu",
  //   name: "create-package-menu",
  //   component: () => import("../pages/package/CreateMenu.vue"),
  //   meta: {
  //     text: "Create Menu List",
  //     parent: {
  //       name: "Package",
  //       disabled: false,
  //       path: "/packages/create/:id",
  //       exact: true,
  //       meta: {
  //         text: "Create Package",
  //         parent: {
  //           name: "Package",
  //           disabled: false,
  //           path: "/packages",
  //           exact: true,
  //         },
  //       },
  //     },
  //   },
  // },
  {
    path: "/packages/edit/:id",
    name: "Edit Package",
    component: () => import("../pages/package/edit-package/EditPackage.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.editPackage",
      parent: {
        name: "$vuetify.layout.navigationSidebar.package",
        disabled: false,
        path: "/packages",
        exact: true,
      },
    },
  },
];
