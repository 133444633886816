<template>
  <svg
    v-if="active"
    width="106"
    height="84"
    viewBox="0 0 106 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.75 2.91602V14.0827M69.75 36.416V47.5827M69.75 69.916V81.0827M13.9167 2.91602H92.0833C95.0449 2.91602 97.8852 4.0925 99.9794 6.18666C102.074 8.28081 103.25 11.1211 103.25 14.0827V30.8327C100.288 30.8327 97.4481 32.0092 95.354 34.1033C93.2598 36.1975 92.0833 39.0378 92.0833 41.9993C92.0833 44.9609 93.2598 47.8012 95.354 49.8954C97.4481 51.9895 100.288 53.166 103.25 53.166V69.916C103.25 72.8776 102.074 75.7179 99.9794 77.812C97.8852 79.9062 95.0449 81.0827 92.0833 81.0827H13.9167C10.9551 81.0827 8.1148 79.9062 6.02064 77.812C3.92648 75.7179 2.75 72.8776 2.75 69.916V53.166C5.71158 53.166 8.55187 51.9895 10.646 49.8954C12.7402 47.8012 13.9167 44.9609 13.9167 41.9993C13.9167 39.0378 12.7402 36.1975 10.646 34.1033C8.55187 32.0092 5.71158 30.8327 2.75 30.8327V14.0827C2.75 11.1211 3.92648 8.28081 6.02064 6.18666C8.1148 4.0925 10.9551 2.91602 13.9167 2.91602"
      stroke="url(#paint0_linear_1067_13493)"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1067_13493"
        x1="45.9193"
        y1="-19.1128"
        x2="129.556"
        y2="7.77033"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4478FF" />
        <stop offset="1" stop-color="#45B2FF" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    v-else
    width="106"
    height="84"
    viewBox="0 0 106 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.75 2.91602V14.0827M69.75 36.416V47.5827M69.75 69.916V81.0827M13.9167 2.91602H92.0833C95.0449 2.91602 97.8852 4.0925 99.9794 6.18666C102.074 8.28081 103.25 11.1211 103.25 14.0827V30.8327C100.288 30.8327 97.4481 32.0092 95.354 34.1033C93.2598 36.1975 92.0833 39.0378 92.0833 41.9993C92.0833 44.9609 93.2598 47.8012 95.354 49.8954C97.4481 51.9895 100.288 53.166 103.25 53.166V69.916C103.25 72.8776 102.074 75.7179 99.9794 77.812C97.8852 79.9062 95.0449 81.0827 92.0833 81.0827H13.9167C10.9551 81.0827 8.1148 79.9062 6.02064 77.812C3.92648 75.7179 2.75 72.8776 2.75 69.916V53.166C5.71158 53.166 8.55187 51.9895 10.646 49.8954C12.7402 47.8012 13.9167 44.9609 13.9167 41.9993C13.9167 39.0378 12.7402 36.1975 10.646 34.1033C8.55187 32.0092 5.71158 30.8327 2.75 30.8327V14.0827C2.75 11.1211 3.92648 8.28081 6.02064 6.18666C8.1148 4.0925 10.9551 2.91602 13.9167 2.91602"
      stroke="#AFAFBE"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script setup>
defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});
</script>
<style lang="scss" scoped>
.icon-voucher svg {
  width: 7.179rem;
  height: 5.584rem;
}
</style>
