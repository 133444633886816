import vuetify from "@/plugins/vuetify";
import platinum from "@/assets/images/platinums/platinum.png";
import platinumGold from "@/assets/images/platinums/platinumGold.png";
import hunger from "@/assets/images/platinums/hunger.png";
import {
  times,
  forEach,
  isEmpty,
  isEqual,
  xorWith,
  reduce,
  isNull,
  isUndefined,
} from "lodash-es";
import accounting from "accounting";

export const localeTranslator = (localKey, params = null) =>
  vuetify?.framework?.lang?.translator(localKey, params) ||
  console.warn("[Validation util]: Vuetity locale importing is broken!");

export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const avatarByString = (str) => {
  return str
    .split(" ")
    .map((letter, i) => {
      if (i < 2) return letter.charAt(0);
      return null;
    })
    .join("");
};

export const selectCustomerIconLevel = (value) => {
  return value === 0 ? platinum : value === 1 ? platinumGold : hunger;
};

export const transformToArray = (anyInput) => {
  if (Array.isArray(anyInput)) return anyInput;
  if (!anyInput) return [];
  return [anyInput];
};

export const timeOptions = () => {
  const options = [];
  times(24, (hours) => {
    const mins = ["00", "30"];
    forEach(mins, (min) => {
      options.push({
        value: `${hours < 10 ? "0" + hours : hours}:${min}`,
        label: `${hours < 10 ? "0" + hours : hours}:${min}`,
      });
    });
  });
  options.push({ value: "24:00", label: "24:00" });

  return options;
};

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

export const getAvailableItemObject = (obj) => {
  return reduce(
    obj,
    function (result, value, key) {
      return !isNull(value) && !isUndefined(value) && value
        ? {
            ...result,
            [key]: value,
          }
        : result;
    },
    "",
  );
};

export const formatMoney = (price, currency) => {
  return accounting.formatMoney(price, currency, 0, ",", ".", "%s%v");
};

export const transformToFormData = (
  data,
  formData = new FormData(),
  parentKey = null,
  file = false,
) => {
  if (file) {
    forEach(data, (value, key) => {
      formData.append(`${parentKey}[${key}][image]`, value);
    });
  } else
    forEach(data, (ele) => {
      formData.append(`${parentKey}[]`, ele);
    });
  return formData;
};

export const transformToFormDataDynamic = (
  data,
  formData = new FormData(),
  parentKey = null,
) => {
  forEach(data, (value, key) => {
    if (value === null) return; // else "null" will be added

    let formattedKey = isEmpty(parentKey) ? key : `${parentKey}[${key}]`;

    if (value instanceof File) {
      formData.set(formattedKey, value);
    } else if (value instanceof Array) {
      forEach(value, (ele) => {
        formData.append(`${formattedKey}[]`, ele);
      });
    } else if (value instanceof Object) {
      transformToFormDataDynamic(value, formData, formattedKey);
    } else {
      formData.set(formattedKey, value);
    }
  });
  return formData;
};

export const convertLinkToFile = async (item) => {
  let file = null;
  await fetch(item).then(async (response) => {
    const fileURLSplit = response.url?.split("/");
    const filename = fileURLSplit[fileURLSplit.length - 1];
    const contentType = response.headers.get("content-type");
    const blob = await response.blob();
    file = new File([blob], filename, { type: contentType });
  });

  return file;
};

export const isFile = (input) => "File" in window && input instanceof File;

export const isValidHttpUrl = (string) => {
  const pattern = new RegExp(
    "^([a-zA-Z]+:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+!]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i",
  );
  return pattern.test(string);
};

/// format money
export const formatCash = (n) => {
  if (n < 1e4) return n?.toLocaleString();
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};
