export default {
  performanceReview: "Performance Review",
  ratingReviewPhotos: "Rating, Review & Photos",
  newReviews: "New Reviews",
  newReviewPhotos: "New Review Photos",
  overallScore: "Overall Score",
  reviewBreakdown: "Review Breakdown",
  COVIDMeasureBreakdown: "COVID Measure Breakdown",
  food: "Food",
  ambience: "Ambience",
  service: "Service",
  value: "Value",
  distance: "Distance",
  cleanliness: "Cleanliness",
  protection: "Protection",
  partySizeComparison: "Party Size Comparison",
  existingvsNewCustomers: "Existing vs New customers",
  averageSpendingPerCover: "Average Spending Per Cover",
  cancelledBookings: "Cancelled Bookings",
  existing: "Existing",
  new: "New",
  packageComparison: "Package comparison",
  outletComparison: "Outlet comparison",
  revenueGmv: "Revenue (GMV)",
  confirmedBookingsCovers: "Confirmed Bookings VS Covers",
  pageViewsConfirmedCovers: "Page Views VS Confirmed Covers",
  allotmentCapacityUtilization: "Allotment Capacity Utilization",
  booking: "Bookings",
  covers: "Covers",
  gmv: "GMV (฿)",
  toGmv: "% to GMV",
  revenue: "Revenue",
  toRevenue: "% to Revenue",
  export: "Export",
  noDataAvailable: "No data available.",
  today: "Today",
  last7Days: "Last 7 days",
  last30Days: "Last 30 days",
  last365Days: "Last 365 days",
  selectDate: "Select Date",
  exportPerformanceReview: "Export Performance Review",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  last3Months: "Last 3 Months",
};
