import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import SyncStorage from "./plugins/syncStorage";
import storeReset from "./plugins/storeReset";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(({ store }) => SyncStorage(store, {}));
pinia.use(storeReset);

export default pinia;
