import Rollbar from "rollbar";
import Version from "@/utils/getAppVersion.util";

const modeEnv = import.meta.env.APP_NODE_ENV;
const accessToken = import.meta.env.APP_ROLLBAR_TOKEN;

const ignoreList = [
  "Uncaught TypeError: Cannot redefine property: googletag",
  "TypeError: Cannot redefine property: adoptedStyleSheets",
];

const rollbarConfig = {
  accessToken: accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: ignoreList,
  payload: {
    environment: modeEnv,
    client: {
      javascript: {
        code_version: Version,
      },
    },
  },
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;
