const DefaultLayout = () => import("@/layouts/default/DefaultLayout.vue");

export const allotmentRouter = [
  {
    path: "/allotments",
    component: DefaultLayout,
    children: [
      {
        path: "create",
        name: "create-allotment",
        component: () => import("../pages/allotment/CreateAllotment.vue"),
        meta: {
          text: "$vuetify.layout.navigationSidebar.createAllotment",
          parent: {
            name: "$vuetify.layout.navigationSidebar.allotment",
            disabled: false,
            path: "/allotments",
            exact: true,
          },
        },
      },
      {
        path: "edit",
        name: "edit-allotment",
        component: () => import("../pages/allotment/EditAllotment.vue"),
        meta: {
          text: "$vuetify.layout.navigationSidebar.editAllotment",
          parent: {
            name: "$vuetify.layout.navigationSidebar.allotment",
            disabled: false,
            path: "/allotments",
            exact: true,
          },
        },
      },
      {
        path: ":id?",
        name: "allotments",
        component: () => import("../pages/allotment/Allotment.vue"),
        meta: {
          text: "$vuetify.layout.navigationSidebar.allotment",
          miniSidebar: true,
        },
      },
    ],
  },
];
