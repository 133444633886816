export default {
  inputPlaceholder: "ชื่อแพ็กเกจ",
  createPackage: "สร้างแพ็กเกจ",
  createAPackage: "สร้างแพ็กเกจ",
  deletePackage: "ลบแพ็กเกจทั้งหมด",
  packageList: "รายการแพ็กเกจ",
  packageStatus: "สถานะแพ็กเกจ",
  searchFilters: "ตัวกรองการค้นหา",
  createdDate: "วันที่สร้าง",
  rangeDate: "หลายวัน",
  singleDate: "วันเดียว",
  updatedDate: "อัปเดตเมื่อ",
  endDate: "วันที่สิ้นสุด",
  selectDate: "เลือกวันที่",
  reportType: "ประเภทรายงาน",
  exportPackageList: "ดึงรายงานแพ็กเกจ",
  support: "ฝ่าย Support",
  noResult: "ไม่มีผลลัพธ์",
  gotoAllotment: "ไปที่การจัดการที่นั่ง",
  tableHeader: {
    packageId: "รหัสแพ็กเกจ",
    packageName: "ชื่อแพ็กเกจ",
    packageType: "ประเภทแพ็กเกจ",
    price: "ราคา",
    bookingDuration: "ระยะเวลาการจอง",
    createdAt: "สร้างเมื่อ",
    updateAt: "อัปเดตเมื่อ",
    action: "Action",
  },
  availableMethods: "Available H@H methods",
  charactersLimit_20: "จำกัด 20 ตัวอักษร",
  copyOfBankbook: "สำเนาบัญชีธนาคาร",
  copyOfIdentification: "สำเนาบัตรประชาชน",
  signedEContract: "สัญญาออนไลน์ที่ลงนามแล้ว (ดาวน์โหลดที่นี่)",
  acceptAll: "ยอมรับทั้งหมด",
  acceptedPaymentMethods: "ช่องทางการชำระเงิน",
  accordingToCompany:
    "ผู้มีอำนาจจะต้องเซ็นรับรองสำเนาถูกต้องทุกฉบับ พร้อมประทับตราสำคัญบริษัท หากมีการมอบอำนาจให้ผู้อื่นเซ็นแทนจะต้องมีใบมอบอำนาจรับรอง",
  add: "เพิ่ม",
  addMenuItems: "เพิ่มรายการเมนู",
  addMoreMenuItems: "เพิ่มรายการเมนูอีก",
  addMoreOption: "เพิ่มตัวเลือก",
  addMoreSection: "เพิ่มกลุ่ม",
  addPhoto: "เพิ่มรูปภาพ",
  addSubMenu: "เพิ่มเมนูย่อย",
  allDay: "ทั้งวัน",
  allotmentManagement: "การจัดการการที่นั่ง",
  applyToAllDay: "ใช้กับทุกวัน",
  availableRooms: "ห้องที่ว่างอยู่",
  bookableScheduleAndPeriod: "ช่วงเวลาที่จองได้",
  bookingDuration: "ระยะเวลา การจอง",
  bookingDurationInMinute: "ระยะเวลาการจอง (นาที)",
  btsRoute: "เส้นทางบีทีเอส",
  cancel: "ยกเลิก",
  charactersLimit: "ตัวอักษร",
  chargePolicyEn: "นโยบายการเรียกเก็บเงิน (EN)",
  chargePolicyTh: "นโยบายการเรียกเก็บเงิน (TH)",
  chooseFile: "เลือกไฟล์",
  checkInTime: "Check in time",
  checkOutTime: "Check out time",
  city: "จังหวัด",
  close: "ปิด",
  companyCertificate: "สำเนาใบจดทะเบียนรับรองบริษัท (ออกให้ไม่เกิน 3 เดือน)",
  corporation: "นิติบุคคล",
  country: "ประเทศ",
  coverCmage: "ภาพปก",
  cuisine: "อาหาร",
  currency: "สกุลเงิน",
  createMenuList: "สร้างเมนู",
  closed: "Closed",
  editMenuList: "Edit Menu List",
  dayTimeToReceiveBookings: "วันและเวลาในการรับการจอง",
  debitCreditCard: "บัตรเดบิต/เครดิต",
  delete: "ลบ",
  description: "คำอธิบาย",
  descriptionEn: "คำอธิบาย (EN)",
  descriptiontH: "คำอธิบาย (TH)",
  designMenulist: "ออกแบบเมนู",
  deliveryFee: "Delivery fee",
  delivery: "Delivery",
  district: "เขต",
  doYouAlsoProvideFood: "มีอาหารด้วย หรือไม่?",
  document: "เอกสาร",
  editInfoAndResubmit: "แก้ไขข้อมูลและส่งใหม่",
  editSubMenu: "แก้ไขเมนูย่อย",
  emailAddress: "อีเมล",
  everyday: "ทุกวัน",
  facilities: "สิ่งอำนวยความสะดวก",
  firstBookableTime: "เวลาแรกที่จองได้",
  fixedAmount: "ระบุจำนวนเงิน",
  friday: "วันศุกร์",
  galleryManangement: "จัดการแกลเลอรี่",
  here: "ที่นี่",
  hotelBrand: "แบรนด์โรงแรม",
  howManyVouchers: "คุณต้องการขายเวาเชอร์กี่ใบ?",
  iWantToLimit: "ต้องการจำกัด",
  iWantToSell: "ต้องการขาย",
  importFile: "Import File",
  itemNo: "Item No.",
  kidPricePolicyEN: "นโยบายราคาเด็ก (EN)",
  kidPricePolicyTH: "นโยบายราคาเด็ก (TH)",
  kidsPriceOptional: "ราคาเด็ก (ไม่บังคับ)",
  kidsPrice: "ราคาเด็ก",
  lastBookableTime: "เวลาจองสุดท้าย",
  learnMore: "อ่านเพิ่มเติม",
  location: "ตำแหน่งในแผนที่",
  locationInGoogleMap: "ตำแหน่งใน Google Map",
  logo: "โลโก้",
  menuImage: "เมนูภาพ",
  mainContactPerson: "ผู้ติดต่อหลัก",
  max3Choices: "สูงสุด 3 ตัวเลือก",
  maximumNumberofseats: "จำกัด ที่นั่ง",
  menu: "เมนู",
  menuForPackage: "เมนูสำหรับแพ็กเกจ",
  menuID: "ID เมนู",
  menuSelected: "เมนูที่เลือกไว้",
  menuType: "ประเภทเมนู",
  minimumAdvanceBookingTime: "เวลาจอง ล่วงหน้าขั้นต่ำ",
  minutes: "นาที",
  mins: "นาที",
  monday: "วันจันทร์",
  mrtRoute: "เส้นทาง MRT",
  name: "ชื่อ",
  nameEN: "ชื่อ (en)",
  nameTH: "ชื่อ (th)",
  netPrice: "ราคา Net",
  noFileChosen: "ยังไม่เลือกไฟล์",
  normalChargeUponBooking: "ปกติ (เรียกเก็บเงินทันทีที่จอง)",
  normalSchedule: "กำหนดการปกติ",
  notRequired: "ไม่ต้องการ",
  numberofcourses: "จำนวนคอร์ส",
  numberofdrinks: "จำนวนเครื่องดื่ม",
  numberofadults: "จำนวนผู้ใหญ่",
  onHold: "กันวงเงิน",
  onGoing: "ต่อเนื่อง",
  onSiteRestaurants: "ร้านอาหาร ที่อยู่ที่เดียวกัน",
  operatingHours: "เวลาเปิด (เปิด - ปิด)",
  originalPrice: "ราคาปกติ",
  occursFrom: "เริ่มเมื่อ",
  open: "เปิด",
  packageMenu: "แพ็กเกจและเมนู",
  packageBasicInfo: "แพ็กเกจข้อมูลพื้นฐาน",
  packageNameEN: "ชื่อแพ็กเกจ (en)",
  packageNameTH: "ชื่อแพ็กเกจ (th)",
  packageType: "ประเภทแพ็กเกจ",
  pasteALink: "วาง URL ลิงก์หรือเลือกไฟล์เพื่ออัปโหลด",
  paymentInfo: "ข้อมูลการชำระเงิน",
  perBooking: "ต่อการจอง",
  perPack: "ต่อแพ็ก",
  perRoom: "ต่อห้อง",
  perOrder: "ต่อคำสั่งซื้อ (ไม่บังคับ)",
  perPerson: "ต่อคน",
  percentage: "เปอร์เซ็นต์",
  periodType: "ประเภทระยะเวลา",
  phoneNumber: "หมายเลขโทรศัพท์",
  photomanagement: "การจัดการภาพถ่าย",
  pleaseComplete: "กรุณาทำตามขั้นตอนเพื่อยืนยันบัญชีของคุณ",
  pleaseContact: "กรุณาติดต่อฝ่าย Support หากคุณต้องการเปลี่ยนประเทศของคุณ",
  pleaseExplain: "กรุณาอธิบายเกี่ยวกับบริการอาหารของคุณ",
  pleaseSelect: "เลือกอย่างน้อยหนึ่งอย่าง",
  pleaseSpecify: "โปรดระบุ Link",
  pleaseUpload:
    "โปรดอัปโหลดเอกสารที่จำเป็นสำหรับการยืนยันบัญชีพาร์ทเนอร์ Hungry Hub",
  popularZone: "โซนยอดนิยม",
  preview: "พรีวิว",
  price: "ราคา",
  pricePerPerson: "ราคาต่อคน",
  priceTiering: "การจัดระดับราคา (ไม่บังคับ)",
  for: "สำหรับ",
  persons: "คน",
  pricing: "การกำหนดราคา",
  primary: "หลัก",
  primaryStorePhone: "ร้านค้าหลัก หมายเลขโทรศัพท์",
  promptPayQR: "PromptPay QR",
  quantityPricing: "ปริมาณและราคา",
  quantity: "ปริมาณ",
  questionClick: "มีคำถาม? คลิกที่ช่วยเหลือ",
  requirePrePayment: "ต้องชำระล่วงหน้า",
  restaurantDetailsLocation:
    "รายละเอียดร้านอาหาร สถานที่ และสิ่งอำนวยความสะดวก",
  restaurantDetailsPlace: "รายละเอียดร้านอาหาร สถานที่ และสิ่งอำนวยความสะดวก",
  restaurantInformation: "ข้อมูลร้านอาหาร",
  role: "หน้าที่",
  saturday: "วันเสาร์",
  save: "บันทึก",
  saveMenuItems: "บันทึกรายการเมนู",
  saveSection: "บันทึกส่วน",
  saveSubMenu: "บันทึกเมนูย่อย",
  schedule: "กำหนดการ",
  seasonalMenu: "เมนูตามฤดูกาล",
  seatAvailable: "ที่นั่งที่เหลือ",
  seatingsBookingInfo: "ข้อมูลที่นั่งและการจอง",
  secondary: "รอง",
  secondaryStorePhoneNo: "หมายเลขโทรศัพท์ สำรอง",
  sectionName: "ชื่อส่วน",
  sectionNameEN: "ชื่อส่วน (en)",
  sectionNameTH: "ชื่อส่วน (th)",
  seeMore: "ดูเพิ่มเติม",
  selection: "ตัวเลือก",
  sellingPeriod: "ระยะเวลาขาย",
  shopeePay: "Shopee Pay",
  shoppingMall: "ห้างสรรพสินค้า",
  shortNameENForSMS: "ชื่อสั้น(EN) สำหรับ SMS",
  shortNameTHForSMS: "ชื่อสั้น (TH) สำหรับ SMS",
  socialMediaWebsite: "Social Media & เว็บไซต์",
  soleProprietorship: "บุคคลธรรมดา",
  specialScheduleOptional: "กำหนดการพิเศษ (ไม่บังคับ)",
  specialSchedule: "กำหนดการพิเศษ",
  starRating: "รีวิว",
  startDate: "วันที่เริ่มต้น",
  storeSetting: "ตั้งค่า ร้านค้า",
  subMenu: "เมนูย่อย",
  subMenuNameEN: "ชื่อเมนูย่อย (en)",
  subMenuNameTH: "ชื่อเมนูย่อย (TH)",
  sunday: "วันอาทิตย์",
  specifyRoomType: "Specify room type",
  specificPeriod: "ระยะเวลาเฉพาะ",
  selfPickup: "Self Pickup",
  thai: "ไทย",
  thankYouYourAccount: "ขอบคุณ! บัญชีของคุณอยู่ระหว่างการตรวจสอบ!",
  theCertificate: "ใบรับรองการลงทะเบียนภาษีมูลค่าเพิ่ม (pp20)",
  thursday: "วันพฤหัสบดี",
  trueMoneyWallet: "True Money Wallet",
  tuesday: "วันอังคาร",
  typeOfExperience: "ประเภทของ Experience",
  typeOfVenue: "ประเภทของสถานที่",
  tier: "Tier",
  useExternalEditor: "ใช้ external editor",
  untill: "จนถึง",
  validityPeriod: "ระยะเวลาที่ใช้ได้",
  voucherNameEN: "ชื่อเวาเชอร์ (en)",
  voucherNameTH: "ชื่อเวาเชอร์ (th)",
  vouchers: "เวาเชอร์",
  wednesday: "วันพุธ",
  youWillHearFromUs: "เราจะติดต่อกลับไปภายใน 5-7 วัน",
  yourMenuList: "รายการเมนูของคุณ",
  roomTypeNameForThisPackage: "Room type name for this package",
  allYouCanEat: "All You Can Eat (AYCE)",
  partyPack: "Party Pack (PP)",
  xperience: "Xperience (XP)",
  hungryHome: "Hungry@Home (HAH)",
  packageisactive: "แพ็กเกจนี้เปิดใช้งานอยู่!",
  deletepackageanyway: "คุณต้องการที่จะลบแพ็กเกจนี้อยู่ดีมั้ย ?",
  areYouSureDelete: "คุณแน่ใจว่าจะลบแพ็กเกจนี้หรือไม่?",
  editAPackageId: "แก้ไขแพ็กเกจ ID",
  youHave1Active: "คุณต้องมีแพ็กเกจที่ใช้งานอย่างน้อย 1 แพ็กเกจ!",
  deleteAllPackage: "คุณต้องการไปต่อและลบ แพ็กเกจทั้งหมดหรือไม่ ",
  packageAll: "แพ็กเกจ: ทั้งหมด",
  selectPackageType: "เลือกแพ็กเกจ",
  pleaseEnter: "กรุณาใส่ลิงก์หรือเลือกรูปที่ต้องการใช้",
  active: "คล่องแคล่ว",
  inactive: "ไม่คล่องแคล่ว",
};
