<template>
  <v-dialog
    v-if="props.showRightDialog"
    :value="props.showRightDialog"
    :width="isMobileScreen ? '100%' : props.width"
    transition="slide-x-reverse-transition"
    content-class="right-side-dialog"
    @click:outside="hiddenDialog"
    @keydown.esc="hiddenDialog"
  >
    <div class="right-dialog-container">
      <div class="right-dialog-header" v-if="showHeader">
        <v-icon class="icon-close" small @click="hiddenDialog">
          $iconCloseDialog
        </v-icon>
        <h3 class="title-head" v-if="props.dialogHeading">
          {{ props.dialogHeading }}
        </h3>
      </div>
      <slot />
      <div class="right-dialog-actions pt-4 d-flex" v-if="showFooter">
        <slot name="right-dialog-footer" />
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { useVuetify } from "@/composables/vuetify.composable";
import { watch, onBeforeUnmount, computed } from "vue";

const emit = defineEmits(["update:showRightDialog"]);
const vuetify = useVuetify();

const props = defineProps({
  showRightDialog: {
    type: Boolean,
    default: () => false,
  },
  width: {
    type: [String, Number],
    default: "33.643rem",
  },
  showHeader: {
    type: [Boolean],
    default: true,
  },
  showFooter: {
    type: [Boolean],
    default: true,
  },
  dialogHeading: {
    type: [String],
    default: "",
  },
  linkTo: {
    type: Array,
    default: () => {},
  },
});

function hiddenDialog() {
  emit("update:showRightDialog", false);
}
const isMobileScreen = computed(() => {
  const screenSize = vuetify.breakpoint.width;
  if (screenSize < 992) {
    return true;
  }
  return false;
});
watch(
  () => props.showRightDialog,
  (value) => {
    const scrollbarWidth = window.innerWidth - window.document.body.clientWidth;
    const htmlEl = window.document.querySelector("html");
    if (value) {
      htmlEl.style.overflow = "hidden";
      htmlEl.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      htmlEl.style.overflow = "";
      htmlEl.style.paddingRight = "";
    }
  },
);

onBeforeUnmount(() => {
  const htmlEl = window.document.querySelector("html");
  htmlEl.style.overflow = "";
  htmlEl.style.paddingRight = "";
});
</script>

<style scoped lang="scss">
@import "./RightSideDialog.scss";
</style>
