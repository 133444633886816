import { getCurrentInstance } from "vue";

export function useVuetify() {
  /** Vue instance */
  const instance = getCurrentInstance();
  if (!instance) {
    console.warn(
      `[vuetify] getCurrentInstance() returned null. Method must be called at the top of a setup() function.;`,
    );

    return undefined;
  }

  return instance.proxy.$vuetify;
}
