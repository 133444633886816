export default {
  inputPlaceholder: "Menu list name",
  menuOverview: "ภาพรวมเมนู",
  menuList: "Menu List",
  menu: "Menu",
  menuItem: "เมนูรายการ",
  menuImage: "เมนูภาพ",
  menuItemText:
    "อัปโหลดเมนูอาหารของคุณตามรายการเพื่อการค้นหาและการจัดการที่ง่ายในอนาคต.",
  menuImageText: "อัปโหลดเมนูที่คุณมีอยู่แล้วเพื่อความรวดเร็ว.",
  total: "ทั้งหมด",
  inactive: "Inactive",
  createMenuItems: "Create Menu Item",
  inputMenuItem: "Menu Item Name",
  deleteMenuItems: "Delete All Menu Items",
  editAMenu: "Edit a menu",
  tableHeader: {
    menuId: "No.",
    photo: "Photo",
    name: "Name",
    description: "Description",
    price: "Price",
    subMenu: "Sub Menu",
    action: "Action",
  },
};
