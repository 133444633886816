export default {
  greatSuccess: "สำเร็จ!",
  yikesSomethingWentWrong: "เอ้อะ! มีบางอย่างผิดปกติ",
  // package
  packageCreateSuccess: "สร้างแพ็กเกจสำเร็จ",
  packageEditSuccess: "แก้ไขแพ็คเกจสำเร็จแล้ว.",
  packageDeleteSuccess: "The package items was successfully deleted.",
  packageUpdateSuccess: "The package items was successfully updated.",
  dupticateSuccess: "ทำซ้ำความสำเร็จ",
  deletePackageSuccess: "ลบแพ็คเกจสำเร็จ",
  tooManyPackages: "Too Many Packages!",
  packagemaximum100:
    "Package maximum is 100, please delete other package then try again.",
  // voucher
  voucherCreateSuccess: "สร้างเวาเชอร์สำเร็จ",
  vouchercodesuccessfullyRedeemed: "The voucher code successfully redeemed.",
  guestsuccessfullyupdated: "อัปเดตรายละเอียดของผู้เข้าพักเรียบร้อยแล้ว.",
  // review management
  sentReviewSuccess: "บันทึกข้อมูลรีวิวเรียบร้อยแล้ว!",
  // analytic
  systemwillsendemail: "ระบบจะส่งอีเมลถึงคุณเพื่อดาวน์โหลดรายการประเมินผล",
  tryagainlater:
    "เราเสียใจที่คุณต้องประสบปัญหาบางอย่าง! กรุณาลองใหม่อีกครั้งในภายหลัง.",
  // account management
  storeUpdatedSuccess: "The store was updated successfully.",
  dropPhoTo: "วางรูปภาพที่นี่เพื่ออัปโหลด",
  chooseImage: "เลือกรูปภาพ",
  maximumUpload: "ขนาดไฟล์อัพโหลดสูงสุด 5 MB.",
};
