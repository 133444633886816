import request from "@/utils/request.util";
import qs from "qs";

export const getListAllotment = async (payload) => {
  const params = {
    params: payload,
  };
  return request.get("inventories", params);
};

export const createAllotment = async (payload) => {
  return request.post("inventories", payload);
};

export const updateAllotment = async (payload) => {
  return request.put("inventories/update_inventory", payload);
};

export const getAllotmentDetail = async ({ id, restaurant_id }) => {
  const params = {
    restaurant_id,
  };
  const query = `${qs.stringify(params)}`;
  return request.get(`inventories/${id}?${query}`);
};

export const updateAllotmentDetail = async (payload) => {
  return request.put("inventories/update_inventory_detail", payload);
};

export const blockAllotment = async (payload) => {
  return request.post("inventories/block", payload);
};

export const getListAllotmentSetter = async (payload) => {
  const query = `${qs.stringify(payload)}`;
  return request.get(`inventories/edit_inventory?${query}`);
};

export const unBlockAllotment = async (payload) => {
  const params = {
    params: payload,
  };
  return request.get("inventories/undo", params);
};
