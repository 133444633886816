<script setup>
const props = defineProps({
  activeColor: {
    type: Boolean,
    default: true,
  },
});
</script>

<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 18L13.9862 13.9791M16.2105 8.60526C16.2105 10.6223 15.4093 12.5567 13.983 13.983C12.5567 15.4093 10.6223 16.2105 8.60526 16.2105C6.58822 16.2105 4.65379 15.4093 3.22753 13.983C1.80127 12.5567 1 10.6223 1 8.60526C1 6.58822 1.80127 4.65379 3.22753 3.22753C4.65379 1.80127 6.58822 1 8.60526 1C10.6223 1 12.5567 1.80127 13.983 3.22753C15.4093 4.65379 16.2105 6.58822 16.2105 8.60526Z"
      stroke="url(#paint0_linear_64_5289)"
      stroke-width="1.5"
      stroke-linecap="round"
      :class="{ normal: !props.activeColor }"
    />
    <defs>
      <linearGradient
        id="paint0_linear_64_5289"
        x1="9.5"
        y1="1"
        x2="9.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#437BE8" />
        <stop offset="1" stop-color="#0F8DFB" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style>
.normal {
  stroke: #afafbe;
}
.icon-white svg path {
  stroke: #ffffff;
}
</style>
