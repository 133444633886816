export default {
  analytics: "การวิเคราะห์",
  performanceReview: "การประเมินผล",
  ranking: "การจัดอันดับ",
  todayGMV: "GMV ของวันนี้",
  yourRankingIn: "Your Ranking In ",
  leader: "Leader",
  average: "เฉลี่ย",
  inPattaya: "in Pattaya",
  yourRankingInCuisine: "อันดับของคุณแบบประเภทร้านอาหาร",
  inJapanese: "in Japanese",
  yourRankingInPackageType: "Your Ranking In Package Type",
  inAYCE: "in AYCE",
  tipsToImproveYourRanking: "เคล็ดลับเพื่อเพิ่มอันดับของคุณ",
  location: "อันดับของคุณแบบประเภทสถานที่",
  cuisine: "อันดับของคุณแบบประเภทร้านอาหาร",
  package_type: "อันดับของคุณตามแพ็กเกจ",
};
