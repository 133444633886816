export default {
  redeem: "Redeem",
  QRCheckIn: "QR Check-in",
  createvoucher: "Create Voucher",
  deletevoucher: "Delete All vouchers",
  createAVoucher: "Create a voucher",
  voucherList: "voucher List",
  editVoucher: "Edit voucher",
  tableHeader: {
    voucherId: "Voucher ID",
    voucherName: "Voucher Name",
    price: "Price",
    quantity: "Quantity",
    qtySold: "Qty sold",
    qtyRedeemed: "Qty Redeemed",
    validityPeriod: "Validity Period",
    action: "Action",
  },
  ///voucher redemption history
  voucherRedemtionHistory: "Voucher Redemption History",
  search: "Search",
  tableHeaderHistory: {
    voucherNumber: "Voucher Number",
    transactionId: "Transaction Id",
    voucherDetails: "Voucher Details",
    guestDetails: "Guest Details",
    redeemedDate: "Redeemed Date / Time",
    redeemedTime: "Redeemed Time",
  },
  basicInformation: "Basic Information",
  voucherNameEN: "Voucher Name (EN)",
  voucherNameTH: "Voucher Name (TH)",
  descriptionEN: "Description (EN)",
  descriptionTH: "Description (TH)",
  packageType: "Package Type",
  learnMore: "Learn More",
  sellingPeriod: "Selling Period",
  validityPeriod: "Validity Period",
  termsConditions: "Terms & Conditions (PDF)",
  pasteALink: "Paste a link URL or choose file to upload",
  quantityPricing: "Quantity & Pricing",
  currency: "Currency",
  originalPrice: "Original Price",
  perVoucher: "Per Voucher",
  netPrice: "Net Price ",
  howManyVouchers: "How many vouchers would you like to sell ?",
  iWantToSell: "I want to sell",
  vouchers: "Vouchers",
  iWantToLimit: "I want to limit",
  perOrder: "Per Order (optional)",
  acceptedPaymentMethods: "Accepted payment methods",
  acceptAll: "Accept All",
  paymentInfo: "Payment Info",
  debitCreditCard: "Debit/Credit Card",
  promptPayQR: "PromptPay QR",
  voucherPrice: "Voucher Price",
  quantityOfVoucher: "Quantity Of Voucher",
  exportVoucherList: "Export Voucher List",
  exportVoucherHistory: "Export Voucher History",
  voucherRedemptionHistory: "Voucher Redemption History",
  durationDate: "Duration date",
  voucherNumber: "Voucher Number",
  editGuestDetails: "Edit Guest Details",
  guestDetails: "Guest Details (Optional)",
  Editguestdetails: "Edit guest details",
  addGuestDetails: "Add Guest Details",
  voucherSellingPriod: "This voucher is in the selling period!",
  wouldYouLikeDeleteVoucher:
    "Would you like to go ahead and delete this voucher anyway ?",
  areyouSuredeleteVoucher: "Are you sure to delete this voucher?",
  support: "Support",
  status: "status",
  voucherisapartof: "This voucher is a part of",
  transactionID: "transaction ID",
  voucherDetails: "Voucher Details",
  guestDetailsOptional: "guest details (Optional)",
  confirmRedemption: "Confirm redemption",
  redeemedDate: "Redeemed Date",
  selectDate: "Select Date",
  noDataAvailable: "No data available",
  voucherRedemption: "Voucher Redemption",
  redeemedOn: "Redeemed on",
  at: "at",
};
