import {
  createVoucher,
  deleteVoucher,
  detailVoucherRedeem,
  duplicateVoucher,
  exportListVoucher,
  getListVoucher,
  getVoucherDetail,
  redeemVoucher,
  updateVoucherDetail,
  updateVoucherRedeem,
} from "@/api/voucher";
import { transformToFormData } from "@/utils/global.util";
import { defineStore } from "pinia";

export const useVoucherStore = defineStore({
  id: "voucher",
  state: () => ({
    isEditVoucher: false,
    basicInfoForm: {
      name_th: null,
      name_en: null,
      description_th: null,
      description_en: null,
      package_type_id: null,
      ticket_group_tncs_attributes: null,
      selling_start_date: null,
      selling_end_date: null,
      valid_start_date: null,
      valid_end_date: null,
      url_link: null,
    },
    acceptAllMethod: false,
    payment_type_ids: [1, 2],
    quantityPricingForm: {
      price: 0,
      discount_price: 0,
      quantity: 0,
      defaultQty: 0,
      limit_per_order: null,
    },
    data: [],
    pagination: {},
    voucherRedeemDetails: null,
    voucherRedeemGuestDetails: null,
  }),
  getters: {
    getListData: (state) => state.data,
    getPagination: (state) => state.pagination,
    getBasicInfo: (state) => state.basicInfoForm,
    getQuantityPricing: (state) => state.quantityPricingForm,
    getPaymentInfo: (state) => state.payment_type_ids,
    isAvailableVoucher: (state) => state.data.length > 0,
  },
  actions: {
    clearVoucher() {
      // basicInfoForm
      this.basicInfoForm.name_en = null;
      this.basicInfoForm.name_th = null;
      this.basicInfoForm.description_en = null;
      this.basicInfoForm.description_th = null;
      this.basicInfoForm.package_type_id = null;
      this.basicInfoForm.ticket_group_tncs_attributes = null;
      this.basicInfoForm.selling_start_date = null;
      this.basicInfoForm.selling_end_date = null;
      this.basicInfoForm.url_link = null;
      this.basicInfoForm.valid_start_date = null;
      this.basicInfoForm.valid_end_date = null;
      // payment_type_ids
      this.acceptAllMethod = true;
      this.payment_type_ids = [1, 2];
      // quantityPricingForm
      this.quantityPricingForm.price = 0;
      this.quantityPricingForm.discount_price = 0;
      this.quantityPricingForm.quantity = 0;
      this.quantityPricingForm.defaultQty = 0;
      this.quantityPricingForm.limit_per_order = null;
    },
    getListVoucher(payload) {
      return new Promise((resolve, reject) => {
        getListVoucher(payload)
          .then((response) => {
            const fmt2 = new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              day: "numeric",
              month: "short",
            });

            const dataFormat = response.data.map((item) => {
              const { attributes, id, type } = item;
              const dataItem = {
                type: type,
                voucherId: id,
                voucherName: attributes.name,
                price: attributes.discount_price,
                quantity: attributes.quantity,
                defaultQty: attributes.quantity,
                qtySold: attributes.total_orders,
                qtyRedeemed: attributes.total_redeemed,
                validityPeriod: fmt2
                  .formatRange(
                    new Date(attributes.valid_start_date),
                    new Date(attributes.valid_end_date),
                  )
                  .replaceAll("–", " - ")
                  .replaceAll("Sept", "Sep"),
                selling_start_date: attributes.selling_start_date,
                selling_end_date: attributes.selling_end_date,
                restaurants: attributes.restaurants,
              };
              return dataItem;
            });
            this.data = dataFormat;

            this.pagination = response.meta;
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getVoucherDetail(id) {
      return new Promise((resolve, reject) => {
        getVoucherDetail(id)
          .then((response) => {
            const voucherDetail = response.data.attributes;
            // basicInfoForm
            this.basicInfoForm.name_en = voucherDetail.name_en;
            this.basicInfoForm.name_th = voucherDetail.name_th;
            this.basicInfoForm.description_en = voucherDetail.description_en;
            this.basicInfoForm.description_th = voucherDetail.description_th;
            this.basicInfoForm.package_type_id = voucherDetail.package_type.id;
            this.basicInfoForm.ticket_group_tncs_attributes =
              voucherDetail.terms_conditions;
            this.basicInfoForm.selling_start_date =
              voucherDetail.selling_start_date;
            this.basicInfoForm.selling_end_date =
              voucherDetail.selling_end_date;
            this.basicInfoForm.url_link = voucherDetail.url_link;
            this.basicInfoForm.valid_start_date =
              voucherDetail.valid_start_date;
            this.basicInfoForm.valid_end_date = voucherDetail.valid_end_date;
            // payment_type_ids
            this.payment_type_ids = voucherDetail.payment_types;
            this.acceptAllMethod = this.payment_type_ids.length >= 2;
            // quantityPricingForm
            this.quantityPricingForm.price = voucherDetail.price.cents / 100;
            this.quantityPricingForm.discount_price =
              voucherDetail.discount_price.cents / 100;
            this.quantityPricingForm.quantity = voucherDetail.quantity;
            this.quantityPricingForm.defaultQty = voucherDetail.quantity;
            this.quantityPricingForm.limit_per_order =
              voucherDetail.limit_per_order;
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    handleEditVoucher(formData) {
      return new Promise((resolve, reject) => {
        updateVoucherDetail(formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createVoucher() {
      return new Promise((resolve, reject) => {
        console.log(this.basicInfoForm);
        const formData = new FormData();
        formData.append("ticket_group[name_th]", this.basicInfoForm.name_th);
        formData.append("ticket_group[name_en]", this.basicInfoForm.name_en);
        formData.append(
          "ticket_group[description_th]",
          this.basicInfoForm.description_th,
        );
        formData.append(
          "ticket_group[description_en]",
          this.basicInfoForm.description_en,
        );
        formData.append(
          "ticket_group[package_type_id]",
          this.basicInfoForm.package_type_id,
        );
        formData.append(
          "ticket_group[selling_start_date]",
          this.basicInfoForm.selling_start_date,
        );
        formData.append(
          "ticket_group[selling_end_date]",
          this.basicInfoForm.selling_end_date,
        );
        formData.append(
          "ticket_group[valid_start_date]",
          this.basicInfoForm.valid_start_date,
        );
        formData.append(
          "ticket_group[valid_end_date]",
          this.basicInfoForm.valid_end_date,
        );
        formData.append("ticket_group[url_link]", this.basicInfoForm.url_link);
        formData.append(
          "ticket_group[quantity]",
          this.quantityPricingForm.quantity,
        );
        formData.append(
          "ticket_group[discount_price]",
          this.quantityPricingForm.discount_price,
        );
        formData.append(
          "ticket_group[limit_per_order]",
          this.quantityPricingForm.limit_per_order,
        );
        formData.append("ticket_group[price]", this.quantityPricingForm.price);
        transformToFormData(
          this.basicInfoForm.ticket_group_tncs_attributes,
          formData,
          "ticket_group[ticket_group_tncs_attributes]",
          true,
        );
        transformToFormData(
          this.payment_type_ids,
          formData,
          "ticket_group[payment_type_ids]",
        );

        createVoucher(formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    duplicateVoucher(payload) {
      return new Promise((resolve, reject) => {
        duplicateVoucher(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    detailVoucherRedeem(formData) {
      this.voucherRedeemDetails = null;
      return new Promise((resolve, reject) => {
        detailVoucherRedeem(formData)
          .then((response) => {
            if (!response.data) {
              reject(response);
              return false;
            }
            this.voucherRedeemDetails = response.data.attributes;
            this.voucherRedeemGuestDetails =
              response.data.attributes.guest_details;
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    redeemVoucher(formData) {
      return new Promise((resolve, reject) => {
        redeemVoucher(formData)
          .then((response) => {
            if (!response.success) {
              reject(response);
              return false;
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateVoucherRedeem(formData) {
      return new Promise((resolve, reject) => {
        updateVoucherRedeem(formData)
          .then((response) => {
            if (!response.success) {
              reject(response);
              return false;
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteVoucher(payload) {
      return new Promise((resolve, reject) => {
        deleteVoucher(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    exportListVoucher(payload) {
      return new Promise((resolve, reject) => {
        exportListVoucher(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
