<template>
  <div>
    <div class="env-switcher">
      <v-btn class="mx-2" fab dark large color="red" @click="showDialog = true">
        <v-icon dark class="icon"> $iconGearConfig </v-icon>
      </v-btn>
    </div>

    <RightSideDialog
      dialogHeading="Environment Switcher"
      :showRightDialog.sync="showDialog"
      class="custom-right-dialog"
    >
      <v-container fluid class="custom-container">
        <div>
          <v-radio-group
            v-model="radioGroup"
            class="mt-0 pt-0 v-input-radio item-radio"
          >
            <v-radio
              v-for="(n, index) in envOptions"
              :key="index"
              :label="n.label"
              :value="n.value"
            ></v-radio>
          </v-radio-group>

          <v-text-field
            v-model="radioGroup.baseApiUrl"
            label="Base API URL"
            outlined
            :readonly="radioGroup.envKey !== 'custom'"
          ></v-text-field>

          <v-text-field
            v-model="radioGroup.baseApiRestaurantUrl"
            label="Base API Restaurant URL"
            outlined
            :readonly="radioGroup.envKey !== 'custom'"
          ></v-text-field>
        </div>
        <div>
          <v-btn block color="primary" @click="save">Save</v-btn>
        </div>
      </v-container>
    </RightSideDialog>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import {
  storageSwitcher,
  getStorageSwitcher,
  setStorageSwitcher,
} from "@/utils/storage.util";
import RightSideDialog from "@/components/dialogs/right-side-dialog/RightSideDialog.vue";

const envBaseUrlApi =
  import.meta.env.APP_BASE_API_URL ||
  "https://hh-engineering.my.id/api/partner/v1/";
const envBaseUrlApiRestaurant =
  import.meta.env.APP_BASE_API_RESTAURANT_URL ||
  "https://hh-engineering.my.id/api/v5/restaurants/";

const envOptions = [
  {
    label: "Use Env",
    value: {
      envKey: "env",
      baseApiUrl: envBaseUrlApi,
      baseApiRestaurantUrl: envBaseUrlApiRestaurant,
    },
  },
  {
    label: "Use Engineering",
    value: {
      envKey: "engineering",
      baseApiUrl: "https://hh-engineering.my.id/api/partner/v1/",
      baseApiRestaurantUrl: "https://hh-engineering.my.id/api/v5/restaurants/",
    },
  },
  {
    label: "Use Ballbot",
    value: {
      envKey: "ballbot",
      baseApiUrl: "https://hh-ballbot.my.id/api/partner/v1/",
      baseApiRestaurantUrl: "https://hh-ballbot.my.id/api/v5/restaurants/",
    },
  },
  {
    label: "Use Venus",
    value: {
      envKey: "venus",
      baseApiUrl: "https://hh-venus.my.id/api/partner/v1/",
      baseApiRestaurantUrl: "https://hh-venus.my.id/api/v5/restaurants/",
    },
  },
  {
    label: "Use Production",
    value: {
      envKey: "production",
      baseApiUrl: "https://hungryhub.com/api/partner/v1/",
      baseApiRestaurantUrl: "https://hungryhub.com/api/v5/restaurants/",
    },
  },
  {
    label: "Use Custom",
    value: {
      envKey: "custom",
      baseApiUrl: "",
      baseApiRestaurantUrl: "",
    },
  },
];

const showDialog = ref(false);
const radioGroup = ref({
  envKey: "env",
  baseApiUrl: envBaseUrlApi,
  baseApiRestaurantUrl: envBaseUrlApiRestaurant,
});

const fetchStorage = async () => {
  const envData = await getStorageSwitcher("env");
  if (envData) {
    radioGroup.value.envKey = envData.ENV_KEY;
    radioGroup.value.baseApiUrl = envData.APP_BASE_API_URL;
    radioGroup.value.baseApiRestaurantUrl = envData.APP_BASE_API_RESTAURANT_URL;

    if (envData.ENV_KEY === "custom") {
      envOptions[5].value.baseApiUrl = envData.APP_BASE_API_URL;
      envOptions[5].value.baseApiRestaurantUrl =
        envData.APP_BASE_API_RESTAURANT_URL;
    }
  }
};

const save = async () => {
  await setStorageSwitcher(radioGroup.value);
  window.location.reload();
};

onBeforeMount(async () => {
  await storageSwitcher();
  await fetchStorage();
});
</script>

<style scoped lang="scss">
.env-switcher {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 99;

  :hover {
    .icon {
      transform: rotate(90deg);
    }
  }
}

.custom-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 12px;
}

.custom-right-dialog {
  justify-content: start;
}

:deep(.v-icon__component) {
  min-width: 40px;
  min-height: 40px;
}
</style>
