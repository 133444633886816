import { useRoute } from "@/composables/vueRouter.composable";
import { localeTranslator } from "@/utils/global.util";
/**
 * Initialize the menu according to the route
 * @param routes
 * @param filter
 * @returns {*} Menu list
 */
export const createMenus = (routes, filter) => {
  // Apply the filter if provided
  const filteredRoutes = filter ? routes.filter((i) => filter(i)) : routes;

  // Create menus recursively
  const menus = buildMenus(filteredRoutes);

  return menus;
};

// Helper function to build menus
const buildMenus = (routes) => {
  const menus = [];

  routes.forEach((route) => {
    if (isRootRouteWithChildren(route)) {
      const childMenus = buildMenus(route.children);
      menus.push(...childMenus);
    } else {
      menus.push(createMenuItem(route));
    }
  });

  return menus;
};

// Helper function to check if a route is the root route with children
const isRootRouteWithChildren = (route) => {
  return route.path === "/" && route.children;
};

// Helper function to create a single menu item
const createMenuItem = (route) => {
  const meta = route;
  const children = route.children ? buildMenus(route.children) : undefined;

  return {
    text: meta && meta.text ? meta.text : route.resourceName,
    icon: meta ? meta.icon : "",
    path: route.path,
    parent: Boolean(meta.parentId),
    children,
    hidden: meta.hidden,
    disabled: meta.disabled,
    id: route.id,
  };
};

/**
 * Array to tree structure
 * @param list Array
 * @param tree
 * @param parentId
 */
export const listToTree = (list, tree, parentId) => {
  function assertParentId(item, parentId) {
    if (parentId == null) {
      return item.parentId === null || item.parentId === undefined;
    } else {
      return item.parentId === parentId;
    }
  }

  list.forEach((item) => {
    if (assertParentId(item, parentId)) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: [],
      };

      listToTree(list, child.children, item.id);

      if (child?.children?.length <= 0) {
        delete child.children;
      }

      tree.push(child);
    }
  });
};

/**
 *
 * Generate breadcrumbs based on the current route
 * @param {*} currentRoute
 * @param {*} list
 * @param {*} index
 */
export const createBreadCrumbs = (currentRoute, list = [], index = 0) => {
  const route = useRoute();
  if (currentRoute) {
    let text = currentRoute.meta
      ? currentRoute.meta.text
      : currentRoute.resourceName || currentRoute.name;

    list.push({
      text,
      to: currentRoute.path?.replace(":id", route.params?.id),
      exact: currentRoute?.meta?.exact || currentRoute?.exact || false,
      index,
    });

    if (currentRoute.meta && currentRoute.meta.parent) {
      createBreadCrumbs(currentRoute.meta.parent, list, ++index);
    }
  }
  list.sort((o1, o2) => o2.index - o1.index);
  return list;
};

/**
 * set title page on side bar
 * @param {*} to router.afterEach param
 */
export const createTitlePage = async (to) => {
  let objectId = "";
  let title = to.meta?.text
    ? localeTranslator(to.meta.text)
    : to.matched[0]?.meta?.text
    ? localeTranslator(to.matched[0].meta.text)
    : "Portal Partner";
  if (
    to.path.includes("booking") ||
    to.path.includes("vouchers") ||
    to.path.includes("packages")
  ) {
    objectId = to.params?.id || "";
  }
  document.title = `${title}${objectId ? "#" + objectId : ""}`;
};
