import {
  changeStatusPackage,
  deleteAllPackageItem,
  deletePackage,
  duplicatePackage,
  exportListPackage,
  getListPackage,
  createNewPackage,
  getPaymentType,
  getPackageDetails,
  updatePackage,
  getMenuV2PreviewLink,
} from "@/api/package";
import { defineStore } from "pinia";
import {
  transformToFormDataDynamic,
  convertLinkToFile,
  isFile,
} from "@/utils/global.util";
import { useUserStore } from "@/stores/user.store";
// import { useMenuStore } from "@/stores/menu.store";
// import { format } from "date-fns";
import { uniqBy, orderBy } from "lodash-es";

export const usePackageStore = defineStore({
  id: "package",
  state: () => ({
    data: [],
    pagination: {},
    paymentChargeAmount: 0,
    menuLayout: null,
    packageDetaills: null,
    menuSections: [],
    delivery: {
      custom_delivery_fee: true,
      delivery_fee_currency: "thb",
      delivery_fee: 0,
      delivery_fee_per_km: 10,
      free_delivery_km: 5,
      free_delivery_fee_threshold: 5,
    },
    hotelType: {
      specify_room: false,
      custom_room_name: "",
      check_in_time: null,
      check_out_time: null,
    },
    packageForm: {
      package_type: null,
      package: {
        name_en: "",
        name_th: "",
        description_en: "",
        description_th: "",
        menu_type: "img",
        menu_links: null,
        special_agenda: false,
        no_of_courses: 0,
        no_of_drinks: 0,
        no_of_adults: 0,
        require_cc: false,
        charge_type: "on_hold",
        charge_amount_type: "relative",
        charge_policy_en: "en",
        charge_policy_th: "th",
        // specify_room: false, // only hotel
        // custom_room_name: "",
        // check_in_time: "",
        // check_out_time: "",
        reservation_duration: 0,
      },
      agendas_attributes: [],
      restaurant_packages_attributes: [
        {
          auto_extend: false,
          start_date: null,
          end_date: null,
        },
      ],
      package_menus_attributes: [],
      package_attr_attributes: {
        largest_table: 0,
        max_seat: 0,
        minimum_booking_time_in_advance: 0,
        custom_net_price: 0,
        original_price_cents: 0,
        use_kids_price: false,
        charge_policy_link_en: null,
        charge_policy_link_th: null,
        package_payment_types_attributes: [],
        charge_policy_images_attributes: {
          en: {
            id: null,
            image: null,
          },
          th: {
            id: null,
            image: null,
          },
        },
      },
      kids_prices_attributes: [],
      pricing_attributes: [],
    },
    packageMenu: {
      menuLink: null,
      menuType: null,
      menuImg: null,
      menuV2: null,
    },
    closedDays: [],
    paymentTypes: [],
  }),
  getters: {
    getListData: (state) => state.data,
    getPagination: (state) => state.pagination,
  },
  actions: {
    getListPackage(payload) {
      return new Promise((resolve, reject) => {
        getListPackage(payload)
          .then((response) => {
            const formatDate = new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              day: "numeric",
              month: "short",
            });
            const dataFormat = response.data.map((item) => {
              const { attributes, id, type } = item;
              const dataItem = {
                id: id,
                type: type,
                packageId: attributes.package_id,
                status: attributes.active,
                packageName: attributes.package_name,
                packageType: {
                  type: attributes.package_type,
                  typeShort: attributes.package_type_short,
                  color: attributes.color_code,
                },
                price: attributes.net_price,
                bookingDuration: attributes.booking_duration,
                url: attributes.url,
                pricingType: attributes.pricing_type?.replace("per_", ""),
                createAt: formatDate.format(new Date(attributes.created_at)),
                updatedAt: formatDate.format(new Date(attributes.updated_at)),
                menuType: attributes.menu_type,
                menuImg: attributes.menu_img,
                menuLinks: attributes.menu_links,
                menuV2: attributes.hh_menu_v2_html_preview_link,
                isPreviewMode: attributes.is_visible_for_staff,
                restaurantName: attributes.restaurant_name,
              };
              return dataItem;
            });

            const dataActive = dataFormat.filter((item) => item.status);
            const dataPreview = dataFormat.filter((item) => item.isPreviewMode);
            const dataInactive = dataFormat.filter((item) => !item.status);
            this.data = dataActive.concat(dataPreview, dataInactive);
            this.data = [...new Set(this.data)];
            this.pagination = response.meta;
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createNewPackage() {
      const userStore = useUserStore();
      const formData = new FormData();
      const menuFormat = this.packageForm.package_menus_attributes.map(
        (value) => {
          return {
            image: value,
          };
        },
      );
      const menuData = transformToFormDataDynamic(
        menuFormat,
        formData,
        "package[package_menus_attributes]",
      );

      const formatOccurrences = (occurrences, condition) => {
        return (
          occurrences
            ?.filter((v) => condition(v))
            ?.map((occurrence) => ({
              start_time: occurrence.start_time || "",
              end_time: occurrence.end_time || "",
              start_date: occurrence.start_date
                ? new Date(occurrence.start_date).toString()
                : "",
              end_date: occurrence.end_date
                ? new Date(occurrence.end_date).toString()
                : "",
              all_day: occurrence.all_day,
            })) || []
        );
      };
      const transformAgenda = (value, index) => {
        const singleOccurrencesData = formatOccurrences(
          value.single_occurrences,
          (v) => v.open && !v.hide,
        );
        const exceptionOccurrencesData = formatOccurrences(
          value.single_occurrences,
          (v) => !v.open && !v.hide,
        );

        return {
          mon: value.mon,
          tue: value.tue,
          wed: value.wed,
          thu: value.thu,
          fri: value.fri,
          sat: value.sat,
          sun: value.sun,
          start_time: value.start_time,
          end_time: value.end_time,
          single_occurrences: transformToFormDataDynamic(
            singleOccurrencesData,
            formData,
            `package[agendas_attributes][${index}][single_occurrences]`,
          ),
          exception_occurrences: transformToFormDataDynamic(
            exceptionOccurrencesData,
            formData,
            `package[agendas_attributes][${index}][exception_occurrences]`,
          ),
        };
      };
      const agendaFormat = this.packageForm.agendas_attributes
        ?.filter((v) => !v.hide)
        ?.map((value, index) => transformAgenda(value, index));

      const agendaData = transformToFormDataDynamic(
        agendaFormat,
        formData,
        "package[agendas_attributes]",
      );
      const paymenTypeFormat =
        this.packageForm.package_attr_attributes.package_payment_types_attributes
          ?.filter((v) => !v.hide)
          ?.map((type) => {
            return {
              payment_type_id: type,
            };
          });
      const paymenTypeData = transformToFormDataDynamic(
        paymenTypeFormat,
        formData,
        "package[package_attr_attributes][package_payment_types_attributes]",
      );
      const restaurantPackData = transformToFormDataDynamic(
        this.packageForm.restaurant_packages_attributes,
        formData,
        "package[restaurant_packages_attributes]",
      );

      const createBasePricingObject = (value) => ({
        price_cents: value.price_cents * 100,
        price_currency: "thb",
        pricing_type: value.pricing_type,
      });
      const addAYCEProperties = (obj, value) => {
        obj.min_seat = value.min_seat;
        obj.max_seat = value.max_seat;
        return obj;
      };
      const addPPProperties = (obj, value) => {
        obj.per_pack = value.per_pack;
        return obj;
      };
      const pricingFormat = this.packageForm.pricing_attributes
        ?.filter((v) => !v.hide)
        ?.map((value) => {
          let obj = createBasePricingObject(value);

          if (this.packageForm.package_type === "ayce") {
            obj = addAYCEProperties(obj, value);
          } else if (this.packageForm.package_type === "pp") {
            obj = addPPProperties(obj, value);
          }

          return obj;
        });

      const pricingData = transformToFormDataDynamic(
        pricingFormat,
        formData,
        "package[pricing_attributes]",
      );

      const kidPricingFormat = this.packageForm.kids_prices_attributes
        ?.filter((v) => !v.hide)
        ?.map((value) => {
          const obj = {
            policy_en: value.policy_en,
            policy_th: value.policy_th,
            price_cents: value.price_cents * 100,
            price_currency: value.price_currency,
          };

          return obj;
        });
      const kidPricingData = transformToFormDataDynamic(
        kidPricingFormat,
        formData,
        "package[kids_prices_attributes]",
      );

      const menuItemsFormat = this.menuSections.map((menu, index) => {
        const menusItemIDs = menu.menuListID.map((menuItem, index) => {
          return {
            id: menuItem.id,
            index_number: index,
          };
        });
        return {
          name_en: menu.nameEn,
          name_th: menu.nameTh,
          description_en: menu.descEn,
          description_th: menu.descTh,
          quantity_limit: menu.quantity,
          seasonal: menu.seasonalMenu,
          seasonal_start_date: menu.startDate,
          seasonal_end_date: menu.endDate,
          priority: index,
          menus_attributes: transformToFormDataDynamic(
            menusItemIDs,
            formData,
            "package[menu_sections_attributes][menus_attributes]",
          ),
        };
      });

      const menuItemsData = transformToFormDataDynamic(
        menuItemsFormat,
        formData,
        "package[menu_sections_attributes]",
      );

      const chargePolicyImage = Object.entries(
        this.packageForm.package_attr_attributes
          .charge_policy_images_attributes,
      ).map((v) => {
        const hasImage = (item) => !!item[1].image;
        if (!hasImage(v)) return null;

        return {
          id: v[1].id,
          image: v[1].image,
          language: v[0],
        };
      });

      const chargePolicyImageData = transformToFormDataDynamic(
        chargePolicyImage,
        formData,
        "package[package_attr_attributes][charge_policy_images_attributes]",
      );

      let objectpackage = {
        ...this.packageForm.package,
        package_attr_attributes: {
          ...this.packageForm.package_attr_attributes,
          original_price_cents:
            this.packageForm.package_attr_attributes.original_price_cents * 100,
          use_kids_price: !!kidPricingFormat.length,
          package_payment_types_attributes: paymenTypeData,
          charge_policy_images_attributes: chargePolicyImageData,
        },
        package_menus_attributes: menuData,
        agendas_attributes: agendaData,
        restaurant_packages_attributes: restaurantPackData,
        pricing_attributes: pricingData,
        kids_prices_attributes: kidPricingData,
      };

      if (this.packageForm.package.menu_type) {
        objectpackage.menu_sections_attributes = menuItemsData;
      }

      switch (this.packageForm.package.charge_amount_type) {
        case "fixed":
          objectpackage.fixed_charge_amount_cents = this.paymentChargeAmount;
          break;

        default:
          objectpackage.relative_charge_percent = this.paymentChargeAmount;
          break;
      }

      if (this.packageForm.package_type === "hah") {
        objectpackage = {
          ...objectpackage,
          ...this.delivery,
        };
      }

      if (
        userStore.restaurantInfo.outlet_type === "hotel" &&
        this.packageForm.package_type === "xp"
      ) {
        objectpackage = {
          ...objectpackage,
          ...this.hotelType,
        };
      }

      const payloadToFormData = {
        package_type: this.packageForm.package_type,
        package: objectpackage,
      };

      const postData = transformToFormDataDynamic(
        payloadToFormData,
        formData,
        null,
      );

      return new Promise((resolve, reject) => {
        createNewPackage(postData)
          .then((response) => {
            console.log("createNewPackage", response);
            if (response?.success === false) {
              reject(response);
              return;
            }

            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPaymentType() {
      return new Promise((resolve, reject) => {
        getPaymentType()
          .then((response) => {
            this.paymentTypes = response.data;
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPackageDetails({ id, payload }) {
      return new Promise((resolve, reject) => {
        getPackageDetails({ id, payload })
          .then(async (response) => {
            // const menuStore = useMenuStore();
            const packageDetails = response.data.attributes;
            this.packageDetaills = packageDetails;

            const processMenuImages = async (menuImages) => {
              if (!menuImages?.length) return [];
              const orderedImages = orderImages(menuImages);
              const processedImages = await convertImages(orderedImages);
              return processedImages;
            };
            const orderImages = (menuImages) => {
              return orderBy(menuImages, (item) => item.index_number);
            };
            const convertImages = async (images) => {
              return await Promise.all(
                images.map(async (item) => await convertImage(item)),
              );
            };
            const convertImage = async (item) => {
              if (isFile(item?.url)) {
                return item;
              }
              try {
                return await convertLinkToFile(item.url);
              } catch (err) {
                return item;
              }
            };
            this.packageForm.package_menus_attributes = await processMenuImages(
              packageDetails.menu_img,
            );

            const getValueOrDefault = (value, defaultValue) =>
              value ?? defaultValue;

            this.packageMenu.menuLink = packageDetails.menu_links;
            this.packageMenu.menuType = packageDetails.menu_type;
            this.packageMenu.menuImg = packageDetails.menu_img;
            this.packageMenu.menuV2 =
              packageDetails.hh_menu_v2_html_preview_link;

            this.packageForm.package_type = packageDetails.package_type_short;
            this.packageForm.package.name_en = packageDetails.package_name_en;
            this.packageForm.package.name_th = packageDetails.package_name_th;
            this.packageForm.package.description_en =
              packageDetails.package_desciption_en;
            this.packageForm.package.description_th =
              packageDetails.package_desciption_th;
            this.packageForm.package.menu_type = packageDetails.menu_type;
            this.packageForm.package.menu_links = packageDetails.menu_links[0];
            this.packageForm.package.special_agenda =
              packageDetails.special_agenda;
            this.packageForm.package.no_of_adults = packageDetails.no_of_adults;
            this.packageForm.package.no_of_courses =
              packageDetails.no_of_courses;
            this.packageForm.package.no_of_drinks = packageDetails.no_of_drinks;
            this.packageForm.package.require_cc = packageDetails.require_cc;
            this.packageForm.package.charge_type = packageDetails.charge_type;
            this.packageForm.package.charge_amount_type =
              packageDetails.charge_amount_type;
            this.packageForm.package.charge_policy_en = getValueOrDefault(
              packageDetails.charge_policy?.name_en,
              "",
            );
            this.packageForm.package.charge_policy_th = getValueOrDefault(
              packageDetails.charge_policy?.name_th,
              "",
            );
            this.packageForm.package.reservation_duration = getValueOrDefault(
              packageDetails.booking_duration,
              0,
            );
            this.packageForm.restaurant_packages_attributes[0].auto_extend =
              packageDetails.auto_extend;
            this.packageForm.restaurant_packages_attributes[0].start_date =
              packageDetails.start_date;

            const determineEndDate = (autoExtend, endDate) => {
              return !autoExtend ? endDate : null;
            };
            this.packageForm.restaurant_packages_attributes[0].end_date =
              determineEndDate(
                packageDetails.auto_extend,
                packageDetails.end_date,
              );

            const packageAttr = packageDetails.package_attr || {};

            this.packageForm.package_attr_attributes.largest_table =
              getValueOrDefault(packageDetails.package_attr?.largest_table, 0);
            this.packageForm.package_attr_attributes.id = getValueOrDefault(
              packageAttr?.id,
              null,
            );
            this.packageForm.package_attr_attributes.custom_net_price =
              getValueOrDefault(packageAttr?.custom_net_price, 0);
            this.packageForm.package_attr_attributes.original_price_cents =
              getValueOrDefault(packageAttr?.original_price_cents, 0) / 100;
            this.packageForm.package_attr_attributes.max_seat =
              getValueOrDefault(packageAttr?.max_seat, 0);
            this.packageForm.package_attr_attributes.minimum_booking_time_in_advance =
              getValueOrDefault(
                packageAttr?.minimum_booking_time_in_advance,
                0,
              );
            this.packageForm.package_attr_attributes.use_kids_price =
              getValueOrDefault(packageAttr?.use_kids_price, false);
            this.packageForm.package_attr_attributes.charge_policy_link_en =
              getValueOrDefault(packageAttr?.charge_policy_link_en, "");
            this.packageForm.package_attr_attributes.charge_policy_link_th =
              getValueOrDefault(packageAttr?.charge_policy_link_th, "");
            this.packageForm.package_attr_attributes.package_payment_types_attributes =
              getValueOrDefault(
                packageDetails.payment_types?.map((v) => v.id),
                [],
              );
            this.packageForm.package_attr_attributes.package_payment_type_ids =
              getValueOrDefault(packageDetails.payment_types, []);

            this.packageForm.package_attr_attributes.charge_policy_images_attributes.en.id =
              getValueOrDefault(
                packageDetails.package_attr.charge_policy_images_en?.[0]?.id,
                null,
              );
            this.packageForm.package_attr_attributes.charge_policy_images_attributes.en.image =
              getValueOrDefault(
                packageDetails.package_attr.charge_policy_images_en?.[0]
                  ?.image_url,
                null,
              );
            this.packageForm.package_attr_attributes.charge_policy_images_attributes.th.id =
              getValueOrDefault(
                packageDetails.package_attr.charge_policy_images_th?.[0]?.id,
                null,
              );
            this.packageForm.package_attr_attributes.charge_policy_images_attributes.th.image =
              getValueOrDefault(
                packageDetails.package_attr.charge_policy_images_th?.[0]
                  ?.image_url,
                null,
              );

            const processPricingAttributes = (pricingArray, isNew = false) => {
              return (
                pricingArray?.map((v) => ({
                  ...v,
                  price_cents: v.price_cents / 100,
                  hide: false,
                  isNew: isNew,
                })) || []
              );
            };
            this.packageForm.kids_prices_attributes = processPricingAttributes(
              packageDetails.kids_prices,
            );
            this.packageForm.pricing_attributes = processPricingAttributes(
              packageDetails.pricings,
              false,
            );

            // Set amount payment by charge type
            switch (packageDetails.charge_amount_type) {
              case "fixed":
                this.paymentChargeAmount =
                  packageDetails.fixed_charge_amount_cents;
                break;

              default:
                this.paymentChargeAmount =
                  packageDetails.relative_charge_percent;
                break;
            }

            this.packageForm.agendas_attributes =
              packageDetails.schedule.map((schedule) => {
                const singleOccurrences =
                  schedule.single_occurrences?.map((v) => {
                    return {
                      ...v,
                      open: true,
                      hide: false,
                    };
                  }) || [];
                const exceptionOccurrences =
                  schedule.exception_occurrences?.map((v) => {
                    return {
                      ...v,
                      open: false,
                      hide: false,
                    };
                  }) || [];

                return {
                  ...schedule,
                  hide: false,
                  single_occurrences: [
                    ...singleOccurrences,
                    ...exceptionOccurrences,
                  ].map((v) => {
                    return {
                      ...v,
                      id: Math.floor(
                        Math.random() * (new Date().getTime() - 0 + 1) + 0,
                      ),
                      open: v.open,
                      hide: false,
                    };
                  }),
                };
              }) || [];

            // Hide menu item in this sprint
            // menuStore.menuListDesign = packageDetails.menu_items.map((item) => {
            //   return {
            //     id: item.id,
            //     nameEn: item.name_en,
            //     nameTh: item.name_th,
            //     descEn: item.description_en,
            //     descTh: item.description_th,
            //     quantity: item.quantity || 0,
            //     seasonalMenu: item.seasonal,
            //     menuListID: item.menu_items,
            //     startDate: item.seasonal_end_date,
            //     endDate: item.seasonal_start_date,
            //     hide: false,
            //   };
            // });

            // Hotel type
            this.hotelType.specify_room = packageDetails.specify_room;
            this.hotelType.custom_room_name = packageDetails.custom_room_name;
            this.hotelType.check_in_time = packageDetails.check_in_time;
            this.hotelType.check_out_time = packageDetails.check_out_time;

            // Delivery
            this.delivery.custom_delivery_fee =
              packageDetails.custom_delivery_fee; //
            this.delivery.delivery_fee_currency =
              packageDetails.delivery_fee_currency; //
            this.delivery.delivery_fee = packageDetails.delivery_fee; //
            this.delivery.delivery_fee_per_km =
              packageDetails.delivery_fee_per_km; //
            this.delivery.free_delivery_km = packageDetails.free_delivery_km; //
            this.delivery.free_delivery_fee_threshold = getValueOrDefault(
              parseInt(packageDetails.free_delivery_fee_threshold),
              0,
            );

            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updatePackage(id) {
      const userStore = useUserStore();
      const formData = new FormData();
      const oldMenus =
        this.packageDetaills?.menu_img?.map((menu) => {
          return {
            id: menu.id,
            _destroy: true,
          };
        }) || [];
      const menuFormat = this.packageForm.package_menus_attributes.map(
        (value, index) => {
          return {
            image: value,
            index_number: index + 1,
          };
        },
      );
      const menuData = transformToFormDataDynamic(
        [...menuFormat, ...oldMenus],
        formData,
        "package[package_menus_attributes]",
      );
      // console.log(menuFormat);
      const agendaFormat = this.packageForm.agendas_attributes.map(
        (value, index) => {
          let isDestroy = null;
          if (value.hide) {
            isDestroy = true;
          } else if (value.isNew) {
            isDestroy = false;
          }

          // const singleOccurrencesData = value.single_occurrences.map(
          //   (occurrence) => {
          //     let isDestroy = null;
          //     if (occurrence.hide) {
          //       isDestroy = true;
          //     } else if (occurrence.isNew) {
          //       isDestroy = false;
          //     }
          //     return {
          //       id: !occurrence.isNew ? occurrence.id : null,
          //       open: occurrence.open,
          //       start_time: occurrence.start_time || "",
          //       end_time: occurrence.end_time || "",
          //       start_date: occurrence.start_date
          //         ? new Date(occurrence.start_date).toString()
          //         : "",
          //       end_date: occurrence.end_date
          //         ? new Date(occurrence.end_date).toString()
          //         : "",
          //       all_day: occurrence.all_day,
          //       _destroy: isDestroy || null,
          //     };
          //   },
          // );
          const singleOccurrencesData =
            value.single_occurrences
              ?.filter((v) => v.open)
              ?.map((occurrence) => {
                // let isDestroy = null;
                // if (occurrence.hide) {
                //   isDestroy = true;
                // } else if (occurrence.isNew) {
                //   isDestroy = false;
                // }
                if (!occurrence.hide) {
                  return {
                    // id: !occurrence.isNew ? occurrence.id : null,
                    start_time: occurrence.start_time || "",
                    end_time: occurrence.end_time || "",
                    start_date: occurrence.start_date
                      ? new Date(occurrence.start_date).toString()
                      : "",
                    end_date: occurrence.end_date
                      ? new Date(occurrence.end_date).toString()
                      : "",
                    all_day: occurrence.all_day,
                    // _destroy: isDestroy || null,
                  };
                }

                return;
              }) || [];
          const exceptionOccurrencesData =
            value.single_occurrences
              ?.filter((v) => !v.open)
              ?.map((occurrence) => {
                // let isDestroy = null;
                // if (occurrence.hide) {
                //   isDestroy = true;
                // } else if (occurrence.isNew) {
                //   isDestroy = false;
                // }

                if (!occurrence.hide) {
                  return {
                    // id: !occurrence.isNew ? occurrence.id : null,
                    start_time: occurrence.start_time || "",
                    end_time: occurrence.end_time || "",
                    start_date: occurrence.start_date
                      ? new Date(occurrence.start_date).toString()
                      : "",
                    end_date: occurrence.end_date
                      ? new Date(occurrence.end_date).toString()
                      : "",
                    all_day: occurrence.all_day,
                    // _destroy: isDestroy || null,
                  };
                }

                return;
              }) || [];
          return {
            id: !value.isNew ? value.id : null,
            mon: value.mon,
            tue: value.tue,
            wed: value.wed,
            thu: value.thu,
            fri: value.fri,
            sat: value.sat,
            sun: value.sun,
            start_time: value.start_time,
            end_time: value.end_time,
            _destroy: isDestroy || null,
            single_occurrences: transformToFormDataDynamic(
              singleOccurrencesData,
              formData,
              `package[agendas_attributes][${index}][single_occurrences]`,
            ),
            exception_occurrences: transformToFormDataDynamic(
              exceptionOccurrencesData,
              formData,
              `package[agendas_attributes][${index}][exception_occurrences]`,
            ),
          };
        },
      );

      const agendaData = transformToFormDataDynamic(
        agendaFormat,
        formData,
        "package[agendas_attributes]",
      );

      const newPackagePaymentIDs =
        this.packageForm.package_attr_attributes.package_payment_types_attributes.map(
          (v) => {
            return {
              id: v,
            };
          },
        );

      const newArr = uniqBy(
        [
          ...(this.packageForm?.package_attr_attributes
            ?.package_payment_type_ids || []),
          ...newPackagePaymentIDs,
        ],
        "id",
      );

      const paymenTypeFormat = newArr.map((v) => {
        let obj = {
          payment_type_id: v.id,
          id: v?.package_payment_type_id || null,
        };

        const package_payment_type =
          this.packageForm.package_attr_attributes.package_payment_types_attributes.find(
            (id) => id == v.id,
          );
        if (!package_payment_type) {
          obj._destroy = true;
        }

        return obj;
      });

      const paymenTypeData = transformToFormDataDynamic(
        paymenTypeFormat,
        formData,
        "package[package_attr_attributes][package_payment_types_attributes]",
      );

      const restaurantPackagesData =
        this.packageForm.restaurant_packages_attributes;
      restaurantPackagesData[0].id = id;
      const restaurantPackData = transformToFormDataDynamic(
        restaurantPackagesData,
        formData,
        "package[restaurant_packages_attributes]",
      );
      const pricingFormat = this.packageForm.pricing_attributes.map((value) => {
        let isDestroy = null;
        if (value.hide) {
          isDestroy = true;
        } else if (value.isNew) {
          isDestroy = false;
        }
        const obj = {
          id: !value.isNew ? value.id : null,
          price_cents: value.price_cents * 100,
          price_currency: "thb",
          pricing_type: value.pricing_type,
          _destroy: isDestroy || null,
        };

        if (this.packageForm.package_type === "ayce") {
          obj.min_seat = value.min_seat;
          obj.max_seat = value.max_seat;
        }

        if (this.packageForm.package_type === "pp") {
          obj.per_pack = value.per_pack;
        }

        return obj;
      });
      const pricingData = transformToFormDataDynamic(
        pricingFormat,
        formData,
        "package[pricing_attributes]",
      );

      const kidPricingFormat = this.packageForm.kids_prices_attributes.map(
        (value) => {
          let isDestroy = null;
          if (value.hide) {
            isDestroy = true;
          } else if (value.isNew) {
            isDestroy = false;
          }
          const obj = {
            id: !value.isNew ? value.id : null,
            policy_en: value.policy_en,
            policy_th: value.policy_th,
            price_cents: value.price_cents * 100,
            price_currency: value.price_currency,
            _destroy: isDestroy || null,
          };

          return obj;
        },
      );
      const kidPricingData = transformToFormDataDynamic(
        kidPricingFormat,
        formData,
        "package[kids_prices_attributes]",
      );

      const chargePolicyImage = Object.entries(
        this.packageForm.package_attr_attributes
          .charge_policy_images_attributes,
      ).map((v) => {
        let obj = {
          id: v[1].id,
          image: v[1].image,
          language: v[0],
        };

        if (v[1].id && !v[1].image) {
          obj._destroy = true;
        }

        if (
          (v[1].id && v[1].image && !isFile(v[1].image)) ||
          (!v[1].id && !v[1].image)
        ) {
          obj = {};
        }

        return obj;
      });

      const chargePolicyImageData = transformToFormDataDynamic(
        chargePolicyImage,
        formData,
        "package[package_attr_attributes][charge_policy_images_attributes]",
      );

      const packageAttrAttributesData =
        this.packageForm.package_attr_attributes;
      delete packageAttrAttributesData.package_payment_type_ids;
      let objectpackage = {
        ...this.packageForm.package,
        package_attr_attributes: {
          ...packageAttrAttributesData,
          original_price_cents:
            this.packageForm.package_attr_attributes.original_price_cents * 100,
          use_kids_price: kidPricingFormat.length ? true : false,
          package_payment_types_attributes: paymenTypeData,
          charge_policy_images_attributes: chargePolicyImageData,
        },
        package_menus_attributes: menuData,
        agendas_attributes: agendaData,
        restaurant_packages_attributes: restaurantPackData,
        pricing_attributes: pricingData,
        kids_prices_attributes: kidPricingData,
      };
      /**
       * This is Menu list items in package.
       * Removed in this phase
       */
      // const menuItemsFormat = this.menuSections.map((menu, index) => {
      //   const menusItemIDs = menu.menuListID.map((menuItem, index) => {
      //     return {
      //       id: menuItem.id,
      //       index_number: index,
      //     };
      //   });
      //   return {
      //     name_en: menu.nameEn,
      //     name_th: menu.nameTh,
      //     description_en: menu.descEn,
      //     description_th: menu.descTh,
      //     quantity_limit: menu.quantity,
      //     seasonal: menu.seasonalMenu,
      //     seasonal_start_date: menu.startDate,
      //     seasonal_end_date: menu.endDate,
      //     priority: index,
      //     menus_attributes: transformToFormDataDynamic(
      //       menusItemIDs,
      //       formData,
      //       "package[menu_sections_attributes][menus_attributes]",
      //     ),
      //   };
      // });

      // const menuItemsData = transformToFormDataDynamic(
      //   menuItemsFormat,
      //   formData,
      //   "package[menu_sections_attributes]",
      // );

      // if (this.packageForm.package.menu_type) {
      //   objectpackage.menu_sections_attributes = menuItemsData;
      // }
      /** End menu item feature */

      switch (this.packageForm.package.charge_amount_type) {
        case "fixed":
          objectpackage.fixed_charge_amount_cents = this.paymentChargeAmount;
          break;

        default:
          objectpackage.relative_charge_percent = this.paymentChargeAmount;
          break;
      }

      if (this.packageForm.package_type === "hah") {
        objectpackage = {
          ...objectpackage,
          ...this.delivery,
        };
      }

      if (
        userStore.restaurantInfo.outlet_type === "hotel" &&
        this.packageForm.package_type === "xp"
      ) {
        objectpackage = {
          ...objectpackage,
          ...this.hotelType,
        };
      }

      const payloadToFormData = {
        package_type: this.packageForm.package_type,
        package: objectpackage,
      };

      const postData = transformToFormDataDynamic(
        payloadToFormData,
        formData,
        null,
      );

      return new Promise((resolve, reject) => {
        updatePackage(id, postData)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }

            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    handleChangeStatus(payload) {
      return new Promise((resolve, reject) => {
        changeStatusPackage(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    exportListPackage(payload) {
      return new Promise((resolve, reject) => {
        exportListPackage(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    duplicatePackage(payload) {
      return new Promise((resolve, reject) => {
        duplicatePackage(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deletePackage(id) {
      return new Promise((resolve, reject) => {
        deletePackage(id)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteAllPackageItem() {
      return new Promise((resolve, reject) => {
        deleteAllPackageItem()
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetPackageForm() {
      this.packageDetaills = null;
      this.paymentChargeAmount = null;
      this.menuSections = [];
      this.delivery = {
        custom_delivery_fee: true,
        delivery_fee_currency: "thb",
        delivery_fee: 0,
        delivery_fee_per_km: 10,
        free_delivery_km: 5,
      };
      this.hotelType = {
        specify_room: false,
        custom_room_name: "",
        check_in_time: null,
        check_out_time: null,
      };
      this.packageForm = {
        package_type: null,
        package: {
          name_en: "",
          name_th: "",
          description_en: "",
          description_th: "",
          menu_type: "img",
          menu_links: null,
          special_agenda: false,
          no_of_courses: 0,
          no_of_drinks: 0,
          no_of_adults: 0,
          require_cc: false,
          charge_type: "on_hold",
          charge_amount_type: "relative",
          charge_policy_en: "en",
          charge_policy_th: "th",
          // specify_room: false, // only hotel
          // custom_room_name: "",
          // check_in_time: "",
          // check_out_time: "",
          reservation_duration: 0,
        },
        agendas_attributes: [],
        restaurant_packages_attributes: [
          {
            auto_extend: false,
            start_date: null,
            end_date: null,
          },
        ],
        package_menus_attributes: [],
        package_attr_attributes: {
          largest_table: 0,
          max_seat: 0,
          minimum_booking_time_in_advance: 0,
          custom_net_price: 0,
          original_price_cents: 0,
          use_kids_price: false,
          charge_policy_link_en: "",
          charge_policy_link_th: "",
          package_payment_types_attributes: [],
          charge_policy_images_attributes: {
            en: {
              id: null,
              image: null,
            },
            th: {
              id: null,
              image: null,
            },
          },
        },
        kids_prices_attributes: [],
        pricing_attributes: [],
      };
    },
    getMenuV2Preview(link) {
      return new Promise((resolve, reject) => {
        getMenuV2PreviewLink(link)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
