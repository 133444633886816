export default {
  redeem: "ใช้เวาเชอร์",
  QRCheckIn: "เช็คอินด้วย QR",
  createvoucher: "สร้างเวาเชอร์",
  deletevoucher: "Delete All vouchers",
  createAVoucher: "สร้างเวาเชอร์",
  voucherList: "รายการเวาเชอร์",
  editVoucher: "Edit voucher",
  tableHeader: {
    voucherId: "เวาเชอร์ ID",
    voucherName: "ชื่อเวาเชอร์",
    price: "ราคา",
    quantity: "ปริมาณ",
    qtySold: "จำนวนที่ขายแล้ว",
    qtyRedeemed: "จำนวนที่ใช้แล้ว",
    validityPeriod: "ระยะเวลาที่ใช้ได้",
    action: "Action",
  },
  ///voucher redemption history
  voucherRedemtionHistory: "Voucher Redemption History",
  search: "ค้นหา",
  tableHeaderHistory: {
    voucherNumber: "หมายเลขเวาเชอร์",
    voucherDetails: "รายละเอียดเวาเชอร์",
    transactionId: "รหัสธุรกรรม",
    guestDetails: "รายละเอียดของแขก",
    redeemedDate: "วันเวลาที่แนะนำ",
    redeemedTime: "เวลาที่ใช้เวาเชอร์",
  },
  basicInformation: "ข้อมูลพื้นฐาน",
  voucherNameEN: "ชื่อเวาเชอร์ (EN)",
  voucherNameTH: "ชื่อเวาเชอร์ (TH)",
  descriptionEN: "คำอธิบาย (EN)",
  descriptionTH: "คำอธิบาย (TH)",
  packageType: "ประเภทแพ็กเกจ",
  learnMore: "อ่านเพิ่มเติม",
  sellingPeriod: "ระยะเวลาขาย",
  validityPeriod: "ระยะเวลาที่ใช้ได้",
  termsConditions: "ข้อตกลงและเงื่อนไข(ไฟล์ PDF)",
  pasteALink: "วาง URL ลิงก์หรือเลือกไฟล์เพื่ออัปโหลด",
  quantityPricing: "ปริมาณและราคา",
  currency: "สกุลเงิน",
  originalPrice: "ราคาปกติ",
  perVoucher: "ต่อเวาเชอร์",
  netPrice: "ราคา Net ",
  howManyVouchers: "คุณต้องการขายเวาเชอร์กี่ใบ?",
  iWantToSell: "ต้องการขายl",
  vouchers: "เวาเชอร์",
  iWantToLimit: "ต้องการจำกัด",
  perOrder: "ต่อคำสั่งซื้อ (ไม่บังคับ)",
  acceptedPaymentMethods: "ช่องทางการชำระเงิน",
  acceptAll: "ยอมรับทั้งหมด",
  paymentInfo: "ข้อมูลการชำระเงิน",
  debitCreditCard: "บัตรเดบิต/เครดิต",
  promptPayQR: "PromptPay QR",
  voucherPrice: "ราคาเวาเชอร์",
  quantityOfVoucher: "จำนวนเวาเชอร์",
  exportVoucherList: "ดึงรายการเวาเชอร์",
  exportVoucherHistory: "ดึงรายงานการแลกใช้เวาเชอร์",
  voucherRedemptionHistory: "ประวัติการใช้เวาเชอร์",
  durationDate: "ช่วงเวลา",
  voucherNumber: "หมายเลขเวาเชอร์",
  editGuestDetails: "แก้ไขรายละเอียดของแขก",
  guestDetails: "รายละเอียดแขก (ไม่บังคับ)",
  Editguestdetails: "แก้ไขรายละเอียดของแขก",
  addGuestDetails: "เพิ่มรายละเอียดของแขก",
  voucherSellingPriod: "เวาเชอร์นี้อยู่ในช่วงการขาย!",
  wouldYouLikeDeleteVoucher: "คุณต้องการลบเวาเชอร์นี้อยู่ดีมั้ย ?",
  areyouSuredeleteVoucher: "คุณแน่ใจว่าจะลบเวาเชอร์นี้หรือไม่?",
  support: "ฝ่าย Support",
  status: "สถานะ",
  voucherisapartof: "บัตรกำนัลเป็นส่วนหนึ่งของธุรกรรม",
  transactionID: "transaction ID",
  voucherDetails: "รายละเอียดเวาเชอร์",
  guestDetailsOptional: "รายละเอียดแขก (ไม่บังคับ)",
  confirmRedemption: "ยืนยัน การไถ่ถอน",
  redeemedDate: "วันที่ใช้เวาเชอร์",
  selectDate: "เลือกวันที่",
  noDataAvailable: "ไม่มีข้อมูลที่สามารถใช้ได้",
  voucherRedemption: "การแลกบัตรกำนัล",
  redeemedOn: "แลกเมื่อ",
  at: "ที่",
};
