export default {
  performanceReview: "การประเมินผล",
  ratingReviewPhotos: "คะแนน รีวิวและรูปภาพ",
  newReviews: "รีวิวใหม่",
  newReviewPhotos: "ภาพถ่ายรีวิวใหม่",
  overallScore: "คะแนนโดยรวม",
  reviewBreakdown: "รายละเอียดรีวิว",
  COVIDMeasureBreakdown: "Covid Measure Breakdown",
  food: "อาหาร",
  ambience: "บรรยากาศ",
  service: "Service",
  value: "มูลค่า",
  distance: "ระยะทาง",
  cleanliness: "ความสะอาด",
  protection: "การป้องกัน",
  partySizeComparison: "เปรียบเทียบขนาดของกลุ่ม",
  existingvsNewCustomers: "ลูกค้าเก่า vs ลูกค้าใหม่",
  averageSpendingPerCover: "ยอดใช้จ่าย เฉลี่ยต่อคน",
  cancelledBookings: "การจอง ที่ถูกยกเลิก",
  existing: "Existing",
  new: "New",
  packageComparison: "เปรียบเทียบแพ็กเกจ",
  revenueGmv: "รายได้ (GMV)",
  confirmedBookingsCovers: "เปรียบเทียบยอดการจองและจำนวนคน",
  pageViewsConfirmedCovers: "อัตราการเข้าชม ต่อ คนที่จอง",
  allotmentCapacityUtilization: "อัตราส่วนการจองที่นั่งที่เปิดในระบบ",
  booking: "การจอง",
  covers: "คน",
  gmv: "ยอดขาย (฿)",
  toGmv: "% ต่อ GMV",
  revenue: "รายได้",
  toRevenue: "% ต่อรายได้",
  export: "ดึงรายงาน",
  noDataAvailable: "ไม่มีข้อมูลที่สามารถใช้ได้.",
  today: "วันนี้",
  last7Days: "7 วันที่ผ่านมา",
  last30Days: "30 วันที่ผ่านมา",
  last365Days: "365 วันที่ผ่านมา",
  selectDate: "เลือกวันที่",
  exportPerformanceReview: "ดึงรายงานการประเมินผล",
  thisMonth: "เดือนนี้",
  lastMonth: "เดือนที่แล้ว",
  last3Months: "3 เดือนที่แล้ว",
};
