export const dashboard = {
  todayBooking: "การจองวันนี้",
  upComingBooking: "การจองและที่นั่งที่กำลังจะมาถึง (7 วันถัดไป)",
  thisMonthBooking: "การจองเดือนนี้",
  thisMonthVoucher: "ยอดขายเวาเชอร์เดือนนี้",
  pendingBooking: "การจองรอยืนยัน",
  bookings: "การจอง",
  covers: "คน",
  seatsAvailable: "ที่นั่งที่เหลือ",
  revenue: "รายได้",
  redeemed: "ใช้แล้ว",
  potentialRevenue: "รายได้ที่คาดว่าจะได้รับ",
  manageAllotment: "จัดการที่นั่ง",
  packagesSummary: "สรุปแพ็กเกจ (30 วันที่ผ่านมา)",
  onlinePackages: "แพ็กเกจที่เปิดขาย",
  packages: "แพ็กเกจ",
  bestSeller: "ขายดีที่สุด",
  hungryHubUnlimitedLobster: "Hungry Hub Unlimited Lobster",
  ratingSummary: "สรุปคะแนน (30 วันที่ผ่านมา)",
  rating: "การให้คะแนน",
  reviews: "รีวิว",
  manageBooking: "จัดการการจอง",
  overall: "โดยรวม",
  food: "อาหาร",
  ambience: "บรรยากาศ",
  service: "การบริการ",
  value: "ราคา",
  seeMore: "ดูเพิ่มเติม",
  today: "วันนี้",
  revenueFromBookings: "รายได้จากการจอง",
  thisMonth: "เดือนนี้",
  lastMonth: "เดือนที่แล้ว",
};
