export default {
  reviewManangement: "การจัดการรีวิว",
  reviewList: "รายการรีวิว",
  reviewCustomer: "รีวิวลูกค้า",
  textReviewScore: "คะแนนลูกค้า (กดที่ดาวเพื่อให้คะแนน)",
  comment: "ความคิดเห็น",
  buttonCancel: "Cancel",
  buttonSubmit: "Submit",
  bookingID: "หมายเลขการจอง",
  customerName: "ชื่อลูกค้า",
  packageDetails: "รายละเอียดแพ็กเกจ",
  rating: "การให้คะแนน",
  photoAndReview: "ภาพถ่าย / รีวิว",
  occasion: "โอกาส",
  recommended: "แนะนำ",
  reviewedAt: "รีวิวเมื่อ",
  action: "Action",
  orderStatus: "Order Status:",
  orderLater: "Order Later",
  restaurantRating: "Restaurant Rating",
  covid19Measures: "Covid-19 Measures",
  deliveryRating: "Delivery Rating",
  buttonMenu: "Menu",
  seemore: "See More",
  seeless: "See Less",
  buttonView: "ดู",
  buttonReview: "รีวิว",
  ratingTooltip: {
    overall: "โดยรวม",
    food: "อาหาร",
    ambience: "บรรยากาศ",
    service: "การบริการ",
    value: "มูลค่า",
    socialDistancing: "การเว้นระยะห่าง",
    overallCleanliness: "ความสะอาดโดยรวม",
    staffProtection: "ความระมัดระวังของพนักงาน",
    packaging: "packaging",
    deliveryservice: "Delivery service",
  },
  searchFilters: "ตัวกรองการค้นหา",
  filters: "ตัวกรอง",
  reviewedDate: "วันที่รีวิว",
  rangeDate: "หลายวัน",
  singleDate: "วันเดียว",
  reviewStars: "รีวิว",
  packageType: "ประเภทแพ็กเกจ",
  specificPackage: "แพ็กเกจเฉพาะ",
  Occasion: "โอกาส",
  // customer
  // bookingId: "Booking ID",
  // phoneNo: "Phone No.",
  // email: "Email",
  // booking: "Booking",
  // cancel: "Cancel",
  // noShow: "No Show",
  startDate: "วันที่เริ่มต้น",
  selectDate: "เลือกวันที่",
  endDate: "วันที่สิ้นสุด",
};
