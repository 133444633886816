export default {
  reviewManangement: "Review Manangement",
  reviewList: "Review List",
  reviewCustomer: "Review Customer ",
  textReviewScore: "Customer’s review score (Click the star to rate)",
  comment: "Comments",
  buttonCancel: "Cancel",
  buttonSubmit: "Submit",
  bookingID: "Booking ID",
  customerName: "Customer Name",
  packageDetails: "Package Details",
  rating: "Rating",
  photoAndReview: "Photo / Review",
  occasion: "Occasion",
  recommended: "Recommended",
  reviewedAt: "Reviewed at",
  action: "Action",
  orderStatus: "Order Status:",
  orderLater: "Order Later",
  restaurantRating: "Restaurant Rating",
  covid19Measures: "Covid-19 Measures",
  deliveryRating: "Delivery Rating",
  buttonMenu: "Menu",
  seemore: "See More",
  seeless: "See Less",
  buttonView: "View",
  buttonReview: "Review",
  ratingTooltip: {
    overall: "overall",
    food: "Food",
    ambience: "Ambience",
    service: "service",
    value: "value",
    socialDistancing: "social Distancing",
    overallCleanliness: "Overall Cleanliness",
    staffProtection: "Staff protection",
    packaging: "packaging",
    deliveryservice: "Delivery service",
  },
  searchFilters: "Search Filter",
  filters: "Filters",
  reviewedDate: "Reviewed Date",
  rangeDate: "Range Date",
  singleDate: "Single Date",
  reviewStars: "Review Stars",
  packageType: "Package Type",
  specificPackage: "Specific Package",
  Occasion: "Occasion",
  bookingId: "หมายเลขการจอง",
  startDate: "Start Date",
  selectDate: "Select Date",
  endDate: "End Date",
};
