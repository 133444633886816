export const button = {
  cancel: "ยกเลิก",
  save: "บันทึก",
  update: "อัปเดต",
  register: "ลงทะเบียน",
  goToLogin: "เข้าสูระบบ",
  goToPartnerPortal: "เข้าใช้ Partner Portal",
  buttonEdit: "แก้ไข",
  buttonMenu: "Menu",
  buttonDelete: "Delete",
  confirm: "ยืนยัน",
  export: "ดึงรายงาน",
  filters: "ตัวกรอง",
  submit: "ส่ง",
  clearAll: "ลบทั้งหมด",
  showResults: "แสดงผล",
  create: "สร้าง",
  send: "ส่ง",
  close: "ปิด",
  search: "ค้นหา",
  viewMenu: "ดูเมนู",
  printMenu: "พิมพ์เมนู",
};
